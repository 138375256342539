export const handleScroll = (arg) => {
  const { e, arr, setOffset, offset, itemsCount, setScrollLoader } = arg
  const height = e.target.scrollHeight
  const top = e.target.scrollTop
  const offset_height = e.target.offsetHeight
  const scroll_bar_height = height - (height - offset_height)
  const scroll_bottom = Math.floor(height - (scroll_bar_height + top))
  if (scroll_bottom <= 2 && !(arr.length >= itemsCount)) {
    setTimeout(() => {
      setOffset(offset + 50)
      setScrollLoader(true)
    }, 500)
  }
}
