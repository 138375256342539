import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  unreadMsgCount: 0,
  sendBird: null,
  userSendBird: null
}

const SendBirdSlice = createSlice({
  name: 'sendBirdSlice',
  initialState,
  reducers: {
    addUnreadMsgCount: (state, action) => {
      state.unreadMsgCount = action.payload
    },
    addSendBird: (state, action) => {
      state.sendBird = action.payload
    },
    addUserSendBird: (state, action) => {
      state.userSendBird = action.payload
    }
  }
})

export const sendBirdSliceReducer = SendBirdSlice.reducer
export const {
  addUnreadMsgCount, addSendBird, addUserSendBird
} = SendBirdSlice.actions
