import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { FormattedMessage } from 'react-intl'
import CloseIcon from '@mui/icons-material/Close'
import Input from '../PrivateChat/Input/Input'

const FormChat = ({ file, setFile, showEmoji, setShowEmoji, setMsgToSend, msgToSend, setUpdateMsg, SubmitHandler, updateMsg, focusInput }) => {
  return (
        <form onSubmit={SubmitHandler} className="form-chat">
            {
                updateMsg && (
                    <div className="show-msg-to-edit">
                        <EditIcon/>
                        <div>
                            <p><FormattedMessage id="edit-message" defaultMessage="Editing message"/></p>
                            <CloseIcon
                                onClick={() => {
                                  setMsgToSend('')
                                  setUpdateMsg(false)
                                }}
                                style={{ position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                )
            }
            <Input file={file}
                   setFile={setFile}
                   showEmoji={showEmoji}
                   setShowEmoji={setShowEmoji}
                   setMsgToSend={setMsgToSend}
                   msgToSend={msgToSend}
                   focusInput={focusInput}
            />

        </form>
  )
}

export default FormChat
