import React from 'react'
import colors from '../../Colors.scss'

const ExitBtn = ({
  setShowMenu,
  colorCircle = colors.color_grey_shade,
  colorPath = colors.color_blue,
  width = '30',
  height = '30',
  fillOpacity = '1'
}) => {
  return (
        <svg
            className="cross-btn"
            onClick={() => {
              if (typeof setShowMenu === 'function') {
                setShowMenu(false)
              }
            }}
            width={width}
            height={height}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="18"
                cy="18"
                r="18"
                fill={colorCircle}
                fillOpacity={fillOpacity}
            />
            <path
                d="M25 12.415L23.585 11L18 16.585L12.415 11L11 12.415L16.585 18L11 23.585L12.415 25L18 19.415L23.585 25L25 23.585L19.415 18L25 12.415Z"
                fill={colorPath}
            />
        </svg>
  )
}

export default ExitBtn
