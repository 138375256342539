import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  searchValue: '',
  timeReq: 0

}

const participantPage = createSlice({
  name: 'participantPage',
  initialState,
  reducers: {
    addSearch: (state, action) => {
      state.searchValue = action.payload
    },
    addTimeReq: (state, action) => {
      state.timeReq = action.payload
    }
  }
})

export const participantReducer = participantPage.reducer
export const {
  addSearch, addTimeReq
} = participantPage.actions
