import React from 'react'

const Frame = ({ color = '#A1A9BF' }) => {
  return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4405 9.3999C20.0405 9.7099 21.5105 11.5599 21.5105 15.6099V15.7399C21.5105 20.2099 19.7205 21.9999 15.2505 21.9999H8.74047C4.27047 21.9999 2.48047 20.2099 2.48047 15.7399V15.6099C2.48047 11.5899 3.93047 9.7399 7.47047 9.4099" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 2.5V15.38" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.3484 13.1499L11.9984 16.4999L8.64844 13.1499" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

  )
}

export default Frame
