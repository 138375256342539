import React, { useEffect, useRef, useState } from 'react'
import './AdminPollsPage.scss'
import { useGetPolsListQuery } from '../../redux/api/getLivePollApi/getLivePollApi'
import Paper from '@mui/material/Paper'
import Accordion from '@mui/material/Accordion'
import { ThemeProvider } from '@mui/material'
import { appTheme } from '../../theme'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import parse from 'html-react-parser'
import FormControlLabel from '@mui/material/FormControlLabel'
import colors from '../../Colors.scss'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'

const AdminPollsPage = () => {
  const path = window.location.pathname
  const id = path.split('/').pop()
  const mainContainerRef = useRef(null)

  const { data: pollsList, isFetching, refetch } = useGetPolsListQuery(id, {})

  const [polls, setPolls] = useState(null)
  const [isShowHeader, setIsShowHeader] = useState(true)
  const [leftContainerWidth, setLeftContainerWidth] = useState('50%')
  const [rightContainerWidth, setRightContainerWidth] = useState('50%')
  const [isResizing, setIsResizing] = useState(false)

  const startResizing = () => {
    setIsResizing(true)
  }

  const stopResizing = () => {
    setIsResizing(false)
  }

  const pollsPercentage = (vote_count, total) => {
    if (total !== 0) return Math.floor((vote_count / total) * 100)
    else return 0
  }

  const resizeContainers = (e) => {
    if (!isResizing) return

    const containerRect = mainContainerRef.current?.getBoundingClientRect()

    const position = e.clientX - containerRect.left
    const width =
            position < 340
              ? 340
              : position > containerRect.width - 340
                ? containerRect.width - 340
                : position
    const newLeftWidth = (width / containerRect.width) * 100
    setLeftContainerWidth(`${newLeftWidth}%`)
    setRightContainerWidth(`${100 - newLeftWidth}%`)
  }

  useEffect(() => {
    window.addEventListener('mousemove', resizeContainers)
    window.addEventListener('mouseup', stopResizing)
    return () => {
      window.removeEventListener('mousemove', resizeContainers)
      window.removeEventListener('mouseup', stopResizing)
    }
  }, [resizeContainers, stopResizing])

  return (
        <div className='polls polls__resize-container' ref={mainContainerRef}
             style={{
               gridTemplateColumns: `${leftContainerWidth} ${rightContainerWidth}`,
               userSelect: isResizing ? 'none' : ''
             }}>
            <div className='polls__resize-container--left'>
                {polls && <Button
                    style={{ width: 'fit-content', marginRight: 'auto', marginBottom: '15px', border: '1px solid black' }}
                    onClick={() => {
                      setIsShowHeader(prev => !prev)
                    }}>{isShowHeader ? 'Hidden header' : 'Show header'}</Button>}
                {isFetching ? <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}><CircularProgress/></div> : polls
                  ? <div style={{ width: '100%' }}>
                        <RadioGroup value={polls.user_voted_item}>
                            {isShowHeader &&
                                <Paper key={'paper'} elevation={0}
                                       sx={{
                                         marginBottom: '15px',
                                         borderRadius: '12px !important',
                                         boxShadow: 'none',
                                         padding: '2px 5px',
                                         backgroundColor: '#dedede'
                                       }}>
                                    <Accordion
                                        expanded={false}
                                        sx={{
                                          boxShadow: 'none',
                                          borderRadius: '12px !important',
                                          backgroundColor: '#dedede'
                                        }}
                                    >
                                        <ThemeProvider theme={appTheme}>
                                            <AccordionSummary

                                                expandIcon={<></>}
                                                sx={{
                                                  width: '100%',
                                                  boxSizing: 'border-box'
                                                }}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography
                                                    sx={{
                                                      paddingLeft: '16px'
                                                    }}
                                                    className="polls-question-container-head">
                                                    {polls?.question}
                                                </Typography>
                                            </AccordionSummary>
                                        </ThemeProvider>
                                    </Accordion>
                                </Paper>
                            }
                            {polls?.items.map((ans) => {
                              const pollsWithLink = parse(ans?.value)
                              return (
                                    <div
                                        key={ans.id}
                                        className={'polls-answers-radio text-white'}
                                    >
                                        <FormControlLabel
                                            // disabled={true}
                                            value={false}
                                            style={{
                                              color: 'white',
                                              paddingLeft: '1px'
                                            }}
                                            sx={{ color: 'white' }}
                                            control={
                                                <></>
                                            }

                                            label={Array.isArray(pollsWithLink)
                                              ? <>
                                                    <p>{pollsWithLink[0]}</p>
                                                    <a style={{ color: 'rgb(107, 111, 122)' }}
                                                       target="_blank"
                                                       href={pollsWithLink[1]?.props?.href}
                                                       rel="noreferrer">
                                                        {pollsWithLink[1]?.props?.children} </a>
                                                </>

                                              : pollsWithLink?.type === 'a'
                                                ? <a style={{ color: 'rgb(107, 111, 122)' }}
                                                         target="_blank"
                                                         href={pollsWithLink.props.href}
                                                         rel="noreferrer">
                                                        {pollsWithLink?.props?.children} </a>
                                                : ans.value}

                                        />
                                        {polls.live_updates && (
                                            <p
                                                // style={{
                                                //   color:
                                                //         polls.user_voted_item === ans.id
                                                //           ? '#1976D2'
                                                //           : '#6B6F7A'
                                                // }}
                                            >
                                                {pollsPercentage(
                                                  ans.vote_count,
                                                  polls.vote_count
                                                )}
                                                %
                                            </p>
                                        )}
                                    </div>
                              )
                            })}

                        </RadioGroup>
                    </div> : <div>Please select the poll</div>}</div>
            <div className='polls__resize-container--right'>
                <div
                    onMouseDown={startResizing}
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      backgroundColor: 'transparent',
                      width: '4px',
                      zIndex: 10,
                      cursor: 'col-resize'
                    }}
                >
                </div>
                <Button style={{ width: 'fit-content', marginLeft: 'auto', border: '1px solid black' }}
                        onClick={() => {
                          refetch()
                          setPolls(() => null)
                        }}>Refetch</Button>
                {!isFetching
                  ? pollsList?.map((poll, index) => (
                        <Paper key={index} elevation={0}
                               sx={{
                                 margin: '0 16px',
                                 borderRadius: '12px !important',
                                 boxShadow: 'none',
                                 padding: '2px 5px',
                                 backgroundColor: polls?.id === poll?.id ? '#dedede' : '#f3f4f8'
                               }}>
                            <Accordion
                                expanded={false}
                                onClick={() => {
                                  refetch().then(() => {
                                    setPolls(() => poll)
                                  })
                                }}
                                sx={{
                                  boxShadow: 'none',
                                  borderRadius: '12px !important',
                                  backgroundColor: polls?.id === poll?.id ? '#dedede' : '#f3f4f8'
                                }}
                            >
                                <ThemeProvider theme={appTheme}>
                                    <AccordionSummary
                                        expandIcon={<></>}
                                        sx={{
                                          width: '100%',
                                          boxSizing: 'border-box'
                                        }}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            sx={{
                                              paddingLeft: '16px'
                                            }}
                                            className="polls-question-container-head">
                                            {poll?.question}
                                        </Typography>
                                    </AccordionSummary>
                                </ThemeProvider>
                            </Accordion>
                        </Paper>
                  )) : <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}><CircularProgress/></div>}</div>
        </div>
  )
}

export default AdminPollsPage
