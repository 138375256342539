import { defaultApi } from '../splitApi'
import { addAgendaDays, addAgendaEvents, addLiveEvent, addUpcomingEvent } from '../../slices/agendaSlice/agendaSlice'
import { isSelect, onceScrollAgendaCheck } from '../../slices/globalValue/globalValue'
import { GetAgendaFilterAPI } from '../../../helper/agendaHelper/comingUpEvent'
import { isToDay } from '../../../components/Days/Days'

export const agendaApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query({
      async queryFn ({ eventId, day_id }, _queryApi, _extraOptions, fetchWithB) {
        let days = _queryApi.getState().agendaReducer.agendaDays

        if (!days.length) {
          const dataRefetch = await fetchWithB(`/base/events/${eventId}/agenda/days/`)
          days = dataRefetch.data.items
        }
        isToDay(days).then(async (value) => {
          const { data } = await fetchWithB(`base/events/${eventId}/agenda/${value ? `?day_id=${value}` : ''}`)
          _queryApi.dispatch(onceScrollAgendaCheck(false))

          data?.items.map((event) => {
            const year = +event.day.split('-')[0]
            const month = +event.day.split('-')[1]
            const day = +event.day.split('-')[2]
            const starHour = +event.start.split(':')[0]
            const endHour = +event.end.split(':')[0]
            const starMinutes = +event.start.split(':')[1]
            const endMinutes = +event.end.split(':')[1]
            const nowTimeSeconds = new Date(new Date())
            const startTimeSeconds = new Date(year, month - 1, day, starHour, starMinutes)
            const endTimeSeconds = new Date(year, month - 1, day, endHour, endMinutes)
            if (nowTimeSeconds >= startTimeSeconds && nowTimeSeconds <= endTimeSeconds) {
              _queryApi.dispatch(addLiveEvent(event))
            } else {
              const eventLiveRedux = _queryApi.getState().agendaReducer.liveEvent
              if (eventLiveRedux.id === event.id) {
                _queryApi.dispatch(addLiveEvent({}))
              }
            }
          })

          const skip = await GetAgendaFilterAPI(value, days, eventId, data?.items)

          if (skip?.skip) {
            const response = await fetchWithB(`base/events/${eventId}/agenda/${value ? `?day_id=${value + 1}` : ''}`)
            _queryApi.dispatch(addUpcomingEvent(response?.data?.items[0]))
          } else {
            _queryApi.dispatch(addUpcomingEvent(skip))

            if (+new Date().getDate() < +days[0]?.date.split('-')[2] && new Date().getMonth() < +days[0]?.date.split('-')[1] && +days[0]?.date.split('-')[0] === +new Date().getFullYear()) {
              _queryApi.dispatch(addUpcomingEvent(data?.items[0]))
            }
          }
        })
        const { data } = await fetchWithB(`base/events/${eventId}/agenda/${day_id ? `?day_id=${day_id}` : ''}`)
        _queryApi.dispatch(onceScrollAgendaCheck(false))

        const events = data?.items.map((event) => {
          const year = +event.day.split('-')[0]
          const month = +event.day.split('-')[1]
          const day = +event.day.split('-')[2]
          const starHour = +event.start.split(':')[0]
          const endHour = +event.end.split(':')[0]
          const starMinutes = +event.start.split(':')[1]
          const endMinutes = +event.end.split(':')[1]
          const nowTimeSeconds = new Date(new Date())
          const startTimeSeconds = new Date(year, month - 1, day, starHour, starMinutes)
          const endTimeSeconds = new Date(year, month - 1, day, endHour, endMinutes)
          if (nowTimeSeconds >= startTimeSeconds && nowTimeSeconds <= endTimeSeconds) {
            _queryApi.dispatch(addLiveEvent(event))
            return { ...event, agendaActive: true }
          } else {
            const eventLiveRedux = _queryApi.getState().agendaReducer.liveEvent
            if (eventLiveRedux.id === event.id) {
              _queryApi.dispatch(addLiveEvent({}))
            }
            return { ...event, agendaActive: false }
          }
        })
        _queryApi.dispatch(addAgendaEvents(events))

        isToDay(days).then(value => {
          if (!value) {
            _queryApi.dispatch(isSelect(false))
            const day = days.find(dayValue => {
              if (dayValue.date.split('-').slice(-1)[0] > new Date().getDate() && dayValue.date.split('-').slice(-2)[0] === new Date().getMonth()) {
                return dayValue
              } else {
                if (dayValue.date.split('-').slice(-2)[0] > new Date().getMonth()) {
                  return dayValue
                } else {
                  return false
                }
              }
            })
            if (day?.date.split('-').slice(-1)[0] >= new Date().getDate()) {
              GetAgendaFilterAPI(+day.id, days, eventId, events).then(value => {
                if (!value?.skip) {
                  if (day_id) {
                    const response = fetchWithB(`base/events/${eventId}/agenda/${day_id ? `?day_id=${day_id}` : ''}`)
                    _queryApi.dispatch(addUpcomingEvent(response?.data?.items[0]))
                  }
                } else {
                  _queryApi.dispatch(addUpcomingEvent(value))
                  _queryApi.dispatch(isSelect(true))
                }
              })
            }
          }
        })
        return { data: events }
      }
    }),
    getAgendaDays: build.query({
      async queryFn (eventId, _queryApi, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ(`/base/events/${eventId}/agenda/days/`)
        _queryApi.dispatch(addAgendaDays(response?.data?.items))

        return { data: response?.data?.items }
      }
    }),
    getAgendaDetails: build.query({
      query: ({ eventId, agendaId }) => `/base/events/${eventId}/agenda/${agendaId}/`,
      transformResponse: (response) => {
        return response
      }
    }),
    getSponsor: build.query({
      query: (eventId) => `/base/events/${eventId}/sponsors/`,
      transformResponse: (response) => {
        return {
          sponsors: response?.items,
          count: response?.meta?.total_count
        }
      }
    }),
    getExhibition: build.query({
      query: (eventId) => `/base/events/${eventId}/exhibitors/?limit=1000`,
      transformResponse: (response) => {
        const priorities = new Set()
        for (const item of response.items) {
          priorities.add(item.priority)
        }

        const separatedArr = Array.from(priorities).map((priority) => {
          return response.items.filter((item) => item.priority === priority)
        })
        return separatedArr
      }
    }),
    getVisibleDetails: build.query({
      query: (eventId) => `/base/events/${eventId}/settings/visible_details/`,
      keepUnusedDataFor: 0

    })
  })

})
export const {
  useGetEventsQuery,
  useGetAgendaDaysQuery,
  useGetAgendaDetailsQuery,
  useGetSponsorQuery,
  useGetExhibitionQuery,
  useGetVisibleDetailsQuery
} = agendaApi
