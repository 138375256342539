import Popover from '@mui/material/Popover'
import FilterListIcon from '@mui/icons-material/FilterList'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import React from 'react'
import colors from '../../Colors.scss'
import { FormattedMessage } from 'react-intl'
import CheckIcon from '@mui/icons-material/Check'

export const FilterMenu = ({ anchor, setAnchor, fillterMenu, orderBy, order, setOrder, handleFilterMenu, setOffsetTable, setPage }) => {
  return (
        <>
            <div className="participants-filter" style={{ marginLeft: '20px', backgroundColor: colors.color_white }}>
                <Popover
                    open={Boolean(anchor)}
                    id={anchor ? 'simple-popover-table' : undefined}
                    onClose={() => setAnchor(null)}
                    anchorOrigin={{
                      vertical: 200,
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                >
                    <p className="filter-menu-head"><FormattedMessage id="sort-by" defaultMessage="Sort by"/></p>
                    {fillterMenu?.map((menu) => (
                        <p
                            key={menu?.id}
                            className="filter-menu"
                            onClick={() => {
                              handleFilterMenu(menu)
                              setAnchor(null)
                            }}
                        >
                            {menu?.name} {menu.label === orderBy && <CheckIcon/>}
                        </p>
                    ))}

                </Popover>
                <FilterListIcon
                    onClick={(e) => setAnchor(e.currentTarget)}
                    fontSize="medium"
                />

                {

                    order === ''
                      ? <ArrowDownwardIcon
                            onClick={() => {
                              setOrder('-')
                              setOffsetTable(0)
                              setPage(0)
                            }}/>
                      : <ArrowUpwardIcon
                            onClick={() => {
                              setOrder('')
                              setOffsetTable(0)
                              setPage(0)
                            }}/>
                }
            </div>
        </>
  )
}
