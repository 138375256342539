import ReactPlayer from 'react-player'
import parse from 'html-react-parser'
import { useEffect, useState } from 'react'

export const DescriptionVideo = ({ posterDetail }) => {
  const [orientation, setOrientation] = useState(window?.orientation)
  const [openModal, setOpenModal] = useState(false)
  useEffect(() => {
    window.addEventListener('orientationchange', () => {
      if (window.outerHeight > window.outerWidth) {
        return setTimeout(() => {
          setOrientation(90)
          setOpenModal(true)
        })
      } else {
        return setTimeout(() => {
          setOrientation(0)
          setOpenModal(false)
        })
      }
    })
  }, [])
  return (
        <div className={`${posterDetail?.video && 'hls-player-poster-container'}`}>
            {posterDetail?.video.hls_url
              ? <div className={` ${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe h100'}`}>
                    <ReactPlayer
                        url={posterDetail?.video.hls_url}
                        controls={true}
                        width="100%"
                        height="100%"
                    />
                </div>
              : <div className={` ${orientation === 90 && openModal ? 'full-screen' : 'responsive-iframe h100'}`}>
                    {parse(
                        `<iframe height="100%"   className=${orientation !== 90 && !openModal && 'responsive-iframe'} src=${posterDetail?.video?.embed_code}  allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true"></iframe>`
                    )}
                </div>
            }
        </div>
  )
}
