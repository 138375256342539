import React, { useEffect, useState } from 'react'

// src
import { useSelector } from 'react-redux'
import { useGetSponsorQuery } from '../../redux/api/agendaApi/agendaApi'
import CircularProgress from '@mui/material/CircularProgress'

const Sponsor = () => {
  // env
  const updateSponsor = process.env.REACT_APP_TIME_FOR_UPDATE_SPONSOR

  // reducer
  const liveEvent = useSelector(state => state.agendaReducer.liveEvent)
  const eventId = useSelector(state => state.globalValueReducer.eventId)

  // state
  const [countSponsor, setCountSponsor] = useState(0)

  // api
  const { data, isLoading, isFetching } = useGetSponsorQuery(eventId, {
    pollingInterval: updateSponsor
  })
  const { sponsors, count } = data ?? {}

  useEffect(() => {
    if (sponsors && !liveEvent?.sponsor) {
      const intervalId = setInterval(() => {
        if (countSponsor >= count - 1) {
          setCountSponsor(0)
        } else {
          setCountSponsor((prevState) =>
            prevState + 1
          )
        }
      }, updateSponsor)

      return () => {
        clearInterval(intervalId)
      }
    }

    if (countSponsor >= count) {
      setCountSponsor(0)
    }
  }, [sponsors, liveEvent?.sponsor])

  return (
        <div className="sponsor-logo-add">

            {
                isLoading || isFetching && !liveEvent?.sponsor && !sponsors
                  ? <CircularProgress className="circularProgress"/>
                  : <div className="sponsor-add-wrap">
                        <div

                            onClick={() => {
                              const element = document.createElement('a')
                              element.href = liveEvent?.sponsor ? liveEvent?.sponsor?.url : sponsors[countSponsor]?.url
                              document.body.appendChild(element)
                              element.setAttribute('target', '_blank')
                              element.click()
                            }}
                            className="sponsor-header">

                            {/* <div className="text-add"> */}
                            {/*    <div className="sponsor-img-div"> */}
                            {
                                sponsors[countSponsor]?.image?.download_url &&
                                <img
                                    src={liveEvent?.sponsor ? liveEvent?.sponsor?.download_url : sponsors[countSponsor]?.image?.download_url}
                                    alt={liveEvent?.sponsor ? liveEvent?.sponsor.title : sponsors[countSponsor]?.title}/>
                            }

                            {/* </div> */}
                            {/* </div> */}

                            {/* <h3 className="name-add">{sponsors[countSponsor]?.title}</h3> */}
                        </div>
                        {/* <Button */}
                        {/*    onClick={() => { */}
                        {/*      const element = document.createElement('a') */}
                        {/*      element.href = sponsors[countSponsor]?.url */}
                        {/*      document.body.appendChild(element) */}
                        {/*      element.click() */}
                        {/*    }} */}
                        {/*    sx={{ */}
                        {/*      width: '160px', */}
                        {/*      height: '33px', */}
                        {/*      color: 'white', */}
                        {/*      borderRadius: '16.5px', */}
                        {/*      padding: '11px 18px 8px', */}
                        {/*      margin: '18px 0 14px 0', */}
                        {/*      background: '#1FD662' */}
                        {/*    }} */}
                        {/* > */}
                        {/*    Visit */}
                        {/* </Button> */}
                    </div>}

        </div>
  )
}

export default Sponsor
