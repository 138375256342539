import { getWeekLong } from '../../parser'

export const toDateString = (date) => {
  const newDate = new Date(date).toDateString()
  const finalDate = {
    day: getWeekLong(newDate.split(' ')[0]),
    month: newDate.split(' ')[1] +
            ' ' +
            newDate.split(' ')[2]
  }

  return finalDate
}
