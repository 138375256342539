import { useSelector } from 'react-redux'

export const useGlobalValue = () => {
  return useSelector(state => ({
    eventId: state.globalValueReducer.eventId,
    participantSorting: state.globalValueReducer.participantSorting,
    participantSortingTable: state.globalValueReducer.participantSortingTable,
    slug: state.globalValueReducer.slug,
    onceScrollAgenda: state.globalValueReducer.onceScrollAgenda,
    selectedDate: state.globalValueReducer.selectedDate,
    agendaActive: state.globalValueReducer.agendaActive,
    navigateToAgenda: state.globalValueReducer.navigateToAgenda
  }))
}
export const useAuthValue = () => {
  return useSelector(state => ({
    userId: state.authReducer.userId,
    profileDetails: state.authReducer.user,
    login: state.authReducer.token
  }))
}

export const useAgendaValue = () => {
  return useSelector(state => ({
    agendaEvents: state.agendaReducer.agendaEvents,
    agendaDays: state.agendaReducer.agendaDays
  }))
}
export const useSendBirdValue = () => {
  return useSelector(state => ({
    userSendBird: state.sendBirdSliceReducer.userSendBird
  }))
}
