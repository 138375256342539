import { FormattedMessage } from 'react-intl'
import React from 'react'
import messages from '../../translate/participant/participant'

export const headCellFull = [
  {
    id: 'avatar',
    label: ''
  },
  {
    id: 'first_name',
    label: <FormattedMessage id="first-name" defaultMessage="First name"/>
  },
  {
    id: 'last_name',
    label: <FormattedMessage id="last-name" defaultMessage="Last name"/>
  },
  {
    id: 'company',
    label: <FormattedMessage id="company" defaultMessage="Company"/>

  },
  {
    id: 'title',
    label: <FormattedMessage id="job-title" defaultMessage="Job Title"/>
  },
  {
    id: 'attendee_type',
    label: <FormattedMessage id="attendee_type" defaultMessage="Attendee type"/>
  },
  {
    id: 'chat',
    label: <FormattedMessage id="chat" defaultMessage="Chat"/>

  }
  // {
  //     id: "videoChat",
  //     label: <FormattedMessage id="video-chat" defaultMessage="Video chat"/>
  //     intl.formatMessage({
  //                 defaultMessage:"Video chat",
  //                 id:"video-chat"
  //             })
  // },
]

export const fillterMenu = [
  {
    id: 1,
    label: 'first_name',
    name: <FormattedMessage id="first-name" defaultMessage="First name"/>
  },
  {
    id: 2,
    label: 'last_name',
    name: <FormattedMessage id="last-name" defaultMessage="Last name"/>
  },
  {
    id: 3,
    label: 'institution',
    name: <FormattedMessage id="institution" defaultMessage="Company"/>
  },
  {
    id: 4,
    label: 'job_title',
    name: <FormattedMessage id="job-title" defaultMessage="Job Title"/>
  },
  {
    id: 5,
    label: 'country',
    name: <FormattedMessage id="country" defaultMessage="Country"/>
  },
  {
    id: 6,
    label: 'status_online',
    name: <FormattedMessage id="status-online" defaultMessage="Status online"/>
  },
  {
    id: 7,
    label: 'attendee_type',
    name: <FormattedMessage {...messages.attendee_type}/>
  }
]
