import React from 'react'
import { useSelector } from 'react-redux'

import './Exhibition.scss'
import { useGetExhibitionQuery } from '../../redux/api/agendaApi/agendaApi'

const Exhibition = () => {
  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)

  // api
  const { data: sponsors } = useGetExhibitionQuery(eventId)

  if (!sponsors) return null
  return (
        <div className="exhibition-main">
            {sponsors?.length > 0 &&
                sponsors.map((exhibition, index) => {
                  return (
                        <div key={index} className="exhibition-main__container">
                            {exhibition.map((sortedPriorityItem) => {
                              return (
                                    <div
                                        key={sortedPriorityItem.id}
                                        className="exhibition-main__item"
                                    >
                                        <a
                                            target="_blank"
                                            href={sortedPriorityItem?.url}
                                            rel="noreferrer"
                                        >
                                            <img
                                                className="exhibition-main__item__image"
                                                src={sortedPriorityItem?.image?.download_url}
                                                alt={sortedPriorityItem?.title}
                                            />
                                        </a>
                                    </div>
                              )
                            })}
                        </div>
                  )
                })}
        </div>
  )
}

export default Exhibition
