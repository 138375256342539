import React from 'react'

const Vector = ({ width, height }) => {
  return (
        <svg width={width} height={height} viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1678_12031)">
                <path d="M5 19.9998H27M23.78 20.0098V15.5497M6.09998 19.9998V4.02979C6.09998 2.01979 7.10003 1.00977 9.09003 1.00977H15.32C17.31 1.00977 18.3 2.01979 18.3 4.02979V19.9998M12.25 19.9998V16.2498M23.8 8.88977C22.58 8.88977 21.6 9.86978 21.6 11.0898V13.3597C21.6 14.5797 22.58 15.5598 23.8 15.5598C25.02 15.5598 26 14.5797 26 13.3597V11.0898C26 9.86978 25.02 8.88977 23.8 8.88977Z" stroke="#282828" strokeOpacity="0.5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" shapeRendering="crispEdges"/>
            </g>
            <defs>
                <filter id="filter0_d_1678_12031" x="0.25" y="0.259766" width="31.5" height="28.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1678_12031"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1678_12031" result="shape"/>
                </filter>
            </defs>
        </svg>

  )
}

export default Vector
