import React, { useEffect, useRef, useState } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'

// src
import './Layout.scss'
import './GetLivePoll.scss'
import { useSelector } from 'react-redux'
import { useGetLivePollQuery } from '../../redux/api/homePageApi/homePageApi'
import { useAddPollAnswerMutation } from '../../redux/api/getLivePollApi/getLivePollApi'

export default function GetLivePoll () {
  // hooks
  const Ref = useRef(null)

  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)

  // state
  const [livePoll, setLivePoll] = useState({})
  const [timer, setTimer] = useState('00:00:00')

  // api
  const { data: livePollApi } = useGetLivePollQuery(eventId, {
    pollingInterval: 20000
  })
  const [addPollAnswer] = useAddPollAnswerMutation()
  // const [updatePollAnswer] = useUpdatePollAnswerMutation()
  // const { data: pollsList } = useGetPolsListQuery()

  useEffect(() => {
    if (livePollApi) {
      if (livePollApi?.user_voted) {
        setLivePoll({})
      } else {
        setLivePoll(livePollApi)
        clearTimer(getDeadTime(livePollApi?.expiration))
      }
    }
  }, [livePollApi])

  const livePollPercentage = (vote_count, total) => {
    if (total !== 0) return Math.floor((vote_count / total) * 100)
    else return 0
  }

  const handleRadio = async (pollId, voteId, item_id, user_voted, mutable) => {
    const eventId = window.location.pathname.split('/')[2]
    if (user_voted) {
      // updatePollAnswer({ arg: { eventId, pollId, voteId }, item_id })
      //   .unwrap()
      //   .then(value => {
      //     if (value) {
      //       toast(
      //           <div style={{ display: 'flex', gap: '10px' }}>
      //             <CheckCircleIcon color="primary" />
      //             <p>Poll updated successfully</p>
      //           </div>,
      //           {
      //             position: 'top-right',
      //             autoClose: 2000,
      //             hideProgressBar: false,
      //             closeOnClick: true,
      //             pauseOnHover: true,
      //             draggable: true,
      //             progress: undefined
      //           }
      //       )
      //     }
      //   })

    } else {
      addPollAnswer({ arg: { eventId, pollId }, item_id })
        .unwrap()
        .then(value => {
          if (value) {
            toast(
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <CheckCircleIcon color="primary"/>
                                <p><FormattedMessage id="thank-you-for-your-vote"
                                                     defaultMessage="Thank you for your vote!"/></p>
                            </div>,
                            {
                              position: 'top-right',
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined
                            }
            )
            setTimeout(() => {
              setLivePoll({})
            }, 2000)
          }
        })
    }
  }

  // The state for our timer

  // console.log("sstt",timer)

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / 1000 / 60 / 60) % 24)
    return {
      total,
      hours,
      minutes,
      seconds
    }
  }

  const startTimer = (e) => {
    const { total, hours, minutes, seconds } = getTimeRemaining(e)
    // console.log(total,"-",hours,'-',minutes,'-',seconds)
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
                ':' +
                (minutes > 9 ? minutes : '0' + minutes) +
                ':' +
                (seconds > 9 ? seconds : '0' + seconds)
      )
      // console.log("timee",(hours > 9 ? hours : '0' + hours) + ':' +
      // (minutes > 9 ? minutes : '0' + minutes) + ':'
      // + (seconds > 9 ? seconds : '0' + seconds))
    } else {
      setLivePoll({})
    }
  }

  const clearTimer = (e) => {
    // console.log("ce",e)
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    // setTimer("00:00:10");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current)
    const id = setInterval(() => {
      startTimer(e)
    }, 1000)
    Ref.current = id
  }

  const getDeadTime = (date) => {
    const deadline = new Date(date)
    return deadline
  }

  return (
        <>
            {
                Object.keys(livePoll).length > 0 && (
                    // {/* <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} */}
                    // {/* open={true}> */}
                    <div className="live-poll">
                        <div className="live-poll-card">
                            <div className="live-poll-head">
                                <p className="poll-title"><FormattedMessage id="live-poll" defaultMessage="Live Poll"/></p>
                                <div className="end-side">
                                    <p className="poll-ends-in">
                                        <FormattedMessage id="ends-in" defaultMessage="Ends in"/>: <span
                                        className="ends-timer">{timer}</span>
                                    </p>
                                    <CancelIcon
                                        fontSize="small"
                                        color="primary"
                                        onClick={() => (livePoll.mutable ? setLivePoll({}) : '')}
                                    />
                                </div>
                                {/* sx={{ color: grey[500] }} */}
                            </div>
                            <div>
                                <p className="live-poll-question">{livePoll.question}</p>
                            </div>

                            <div>
                                <RadioGroup defaultValue="">
                                    {livePoll.items.map((ans) => (
                                        <div key={ans.id}
                                             className={`polls-answers-radio ${
                                                 livePoll.user_voted_item === ans.id ? 'gradient' : ''
                                             }`}
                                        >
                                            <FormControlLabel
                                                // disabled={livePoll.poll_closed}
                                                value={ans.id}
                                                control={
                                                    <Radio
                                                        onChange={(e) =>
                                                          handleRadio(
                                                            livePoll.id,
                                                            livePoll.user_voted_id,
                                                            ans.id,
                                                            livePoll.user_voted,
                                                            livePoll.mutable
                                                          )
                                                        }
                                                        checkedIcon={<CheckCircleIcon color="primary"/>}
                                                        // size="small"
                                                    />
                                                }
                                                label={ans.value}
                                            />
                                            {livePoll.live_updates && (
                                                <p
                                                    style={{
                                                      // fontSize: '24px',
                                                      color:
                                                            livePoll.user_voted_item === ans.id
                                                              ? '#1976D2'
                                                              : '#6B6F7A'

                                                    }}
                                                >
                                                    {livePollPercentage(
                                                      ans.vote_count,
                                                      livePoll.vote_count
                                                    )}
                                                    %
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                )
                // </Backdrop>
            }
            {/* <ToastContainer */}
            {/*    position="top-right" */}
            {/*    autoClose={2000} */}
            {/*    hideProgressBar={false} */}
            {/*    newestOnTop={false} */}
            {/*    closeOnClick */}
            {/*    theme="colored" */}
            {/*    rtl={false} */}
            {/*    pauseOnFocusLoss */}
            {/*    draggable */}
            {/*    pauseOnHover */}
            {/* /> */}
        </>
  )
}
