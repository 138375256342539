import { OpenChannelHandler } from '@sendbird/chat/openChannel'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'

export const useOpenChannelHandler = ({ setRefreshMessage, refreshMessage }) => {
  const channelHandler = new OpenChannelHandler({
    onMessageUpdated: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageReceived: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageDeleted: (channel, message) => {
      setRefreshMessage(message)
      toast(<FormattedMessage id="toast-message-deleted" defaultMessage="Message was deleted"/>, {
        position: 'top-right',
        toastId: 'successId'
      })
    }
  })
  return { channelHandler }
}

export default useOpenChannelHandler
