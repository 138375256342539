import React from 'react'
import ButtonMsg from './ButtonMsg'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { downloadAction } from '../../../../../../helper/ButtonHelper/download'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import { FormattedMessage } from 'react-intl'

const Buttons = ({ msg, editAction, deleteAction, user }) => {
  return (
      <>
            {msg?.sender.userId === user?.userId &&
                <>
                    {!msg?.type ? <ButtonMsg callback={() => editAction() }
                               icon={<EditIcon/>}
                               text={<FormattedMessage id="edit" defaultMessage="Edit"/>}/> : ''}
                    <ButtonMsg msg={deleteAction.msg} channel={deleteAction.channel}
                               icon={<DeleteIcon/>} text={<FormattedMessage id="delete" defaultMessage="Delete"/>}/>
                </>
            }
            {msg?.type ? (
                <ButtonMsg callback={() => {
                  downloadAction({ url: msg?.url })
                }} icon={<ArrowCircleDownIcon/>} text={<FormattedMessage id="download" defaultMessage="Download"/>}/>
            ) : ''}
      </>
  )
}

export default React.memo(Buttons)
