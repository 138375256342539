import { makeStyles } from '@material-ui/core'

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 250,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4
}

export const inputStyle = makeStyles({
  border: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
      borderRadius: ' 8px'
    }
  }
})
export const deleteBtn = makeStyles({
  border: {
    '& .MuiPaper-root ': {
      border: '1px solid black',
      borderRadius: '8px'
    },
    '& .MuiPaper-root :hover ': {
      color: 'red'
    }

  }
})
