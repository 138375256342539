import { useState } from 'react'
import { GroupChannelHandler } from '@sendbird/chat/groupChannel'

export const useGroupChannelHandler = () => {
  const [refreshMessage, setRefreshMessage] = useState(null)
  const channelHandler = new GroupChannelHandler({
    onMessageUpdated: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageReceived: (channel, message) => {
      setRefreshMessage(message)
    },
    onMessageDeleted: (channel, message) => {
      setRefreshMessage(message)
    }
  })
  return { refreshMessage, channelHandler }
}

export default useGroupChannelHandler
