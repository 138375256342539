import { defaultApi } from '../splitApi'

export const homePageApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getBannerEvents: (build.query({
      query: () => 'base/events/highlight/',
      transformResponse: (res) => {
        return res?.items
      }

    })),
    getRecentEvents: (build.query({
      async queryFn ({ offset, limit }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const events = await fetchWithBQ(`base/events/recent/?offset=${offset}&limit=${limit}`)
          const length = events.data.meta.total_count
          const newArr = []
          for (let i = 0; i < length; i++) {
            const id = events?.data.items[i]?.id
            const spData = await fetchWithBQ(`base/events/${id}/people/speakers/`)
            newArr.push({ event: events.data.items[i], speakers: spData })
          }
          return { data: newArr }
        } catch (e) {
          return { error: e?.message }
        }
      }
    })),
    getFilterRecent: (build.query({
      async queryFn ({ eventId, offset, limit }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          if (eventId) {
            const events = await fetchWithBQ(`/base/events/recent/?event_type=${eventId}&offset=${offset}&limit=${limit}`)
            const length = events?.data?.meta?.total_count
            const newArr = []
            for (let i = 0; i < length; i++) {
              const id = events?.data.items[i]?.id
              const spData = await fetchWithBQ(`base/events/${id}/people/speakers/`)
              newArr.push({ event: events.data.items[i], speakers: spData })
            }
            return { data: newArr }
          }
        } catch (e) {
          return { error: e?.message }
        }
      }

    })),
    getUpcomingEvents: (build.query({
      async queryFn ({ offset, limit }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const events = await fetchWithBQ(`base/events/upcoming/?offset=${offset}&limit=${limit}`)
          const length = events.data.meta.total_count
          const newArr = []
          for (let i = 0; i < length; i++) {
            const id = events?.data.items[i]?.id
            const spData = await fetchWithBQ(`base/events/${id}/people/speakers/`)
            newArr.push({ event: events.data.items[i], speakers: spData })
          }
          return { data: newArr }
        } catch (e) {
          return { error: e?.message }
        }
      }
    })),
    getFilterUpcoming: (build.query({
      async queryFn ({ eventId, offset, limit }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          if (eventId) {
            const events = await fetchWithBQ(`/base/events/upcoming/?event_type=${eventId}&offset=${offset}&limit=${limit}`)
            const length = events?.data?.meta?.total_count
            const newArr = []
            for (let i = 0; i < length; i++) {
              const id = events?.data.items[i]?.id
              const spData = await fetchWithBQ(`base/events/${id}/people/speakers/`)
              newArr.push({ event: events.data.items[i], speakers: spData })
            }
            return { data: newArr }
          }
        } catch (e) {
          return { error: e?.message }
        }
      }

    })),
    getEventTypes: (build.query({
      query: () => 'base/choices/event_types/',
      transformResponse: (res) => {
        return res?.items
      }
    })),
    getAboutUs: (build.query({
      query: () => 'base/about_us/',
      transformResponse: (res) => {
        return res?.items[0]
      }
    })),
    getEventDesc: build.query({
      query: (eventId) => `base/events/${eventId}/`,
      keepUnusedDataFor: 0
    }),
    eventStream: build.query({
      query: (eventId) => `base/events/${eventId}/streams/`,
      keepUnusedDataFor: 0

    }),
    getLivePoll: build.query({
      query: (eventId) => `base/events/${eventId}/polls/live/`
    })
  })
})
export const {
  useGetBannerEventsQuery, useGetRecentEventsQuery, useGetFilterRecentQuery, useGetEventTypesQuery,
  useGetFilterUpcomingQuery, useGetUpcomingEventsQuery, useGetAboutUsQuery, useGetEventDescQuery,
  useEventStreamQuery, useGetLivePollQuery, useAddPollAnswerMutation
} = homePageApi
