import React from 'react'
// eslint-disable-next-line import/no-absolute-path
import '/node_modules/flag-icons/css/flag-icons.min.css'

const Flags = ({ abbreviation, show }) => {
  if (!show) {
    return ''
  } else {
    return (
            <span className={`fi fi-${abbreviation.toLowerCase()}`} style={{ marginRight: '5px' }}></span>
    )
  }
}

export default Flags
