import { defaultApi } from '../splitApi'

export const getLivePollApi = defaultApi.injectEndpoints({
  tagsType: ['Poll'],
  endpoints: (build) => ({
    getPolsList: (build.query({
      async queryFn (eventId, _queryApi, _extraOptions, fetchWithBQ) {
        const polls = await fetchWithBQ(`/base/events/${eventId}/polls/`)
        const newArr = []
        const length = polls.data.meta.total_count
        for (let i = 0; i < length; i++) {
          const pollId = polls.data?.items[i]?.id
          const poll = await fetchWithBQ(`/base/events/${eventId}/polls/${pollId}/`)
          newArr.push(poll.data)
        }
        return { data: newArr }
      },
      providesTags: ['Poll']
    })),
    getPollsListAnswers: build.query({
      query: ({ eventId, pollId }) => `base/events/${eventId}/polls/${pollId}/`
    }),
    updatePollAnswer: build.mutation({
      query: ({ arg: { eventId, pollId, voteId }, item_id }) => ({
        url: `base/events/${eventId}/polls/${pollId}/vote/${voteId}/`,
        method: 'PATCH',
        body: { item_id }
      }),
      invalidatesTags: ['Poll']
    }),
    addPollAnswer: build.mutation({
      query: ({ arg, item_id }) => ({
        url: `base/events/${arg.eventId}/polls/${arg.pollId}/vote/`,
        method: 'POST',
        body: { item_id }
      }),
      invalidatesTags: ['Poll']
    })
  })
})
export const {
  useGetPolsListQuery, useGetPollsListAnswersQuery,
  useUpdatePollAnswerMutation, useAddPollAnswerMutation
} = getLivePollApi
