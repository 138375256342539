import React, { useEffect, useRef } from 'react'
import { Box, Popper } from '@mui/material'

const ContextHelp = ({
  helpText = {
    id: 1,
    what: 'what text',
    why: 'why text',
    how: 'how text'
  }, open = false, anchorEl, setShowModalHelper = {
    open: false,
    event: null
  }
}) => {
  const ref = useRef()
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowModalHelper({
          open: false,
          event: null
        })
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
  return (
              <Popper
                  ref={ref}
              id={`popover-${helpText.id}`}
              open={open}
              anchorEl={anchorEl}
                  sx={{
                    zIndex: 3
                  }}
          >
              {helpText
                ? <div className='popover-contextHelp'>
                      {helpText.what ? <Box className='popover-contextHelp-box'>
                          {/* <h4>WHAT</h4> */}
                          <p>{helpText.what}</p>
                      </Box> : ''}

                      {helpText.why ? <Box className='popover-contextHelp-box'>
                          {/* <h4>WHY</h4> */}
                          <p>{helpText.why}</p>
                      </Box> : ''}

                      {helpText.how ? <Box className='popover-contextHelp-box'>
                          {/* <h4>HOW</h4> */}
                          <p>{helpText.how}</p>
                      </Box> : ''}
                  </div>
                : ''}

          </Popper>
  )
}

export default ContextHelp
