import React, { useState } from 'react'
import PlaceholderText from './SpeakerAndChairs/Placeholder_text'
import AvatarText from './SpeakerAndChairs/Avatar_text'
import AvatarView from './SpeakerAndChairs/AvatarVeiw'
import TextView from './SpeakerAndChairs/TextView'

const SpeakersAndChairsDetail = ({ agendaDetail, slug, eventId }) => {
  const speakerView = process.env.REACT_APP_SPEAKER_VIEW
  const chair = process.env.REACT_APP_CHAIRS_VIEW
  const peopleViewCount = process.env.REACT_APP_PEOPLE_COUNT_ON_AGENDA_DETAILS
  // state
  const [viewAllCount, setViewAllCount] = useState(peopleViewCount || 5)
  const [viewAllCountChair, setViewAllCountChair] = useState(peopleViewCount || 5)

  const chairsSorted = [...agendaDetail?.chairs]
  const speakersSorted = [...agendaDetail?.speakers]
  chairsSorted?.sort((a, b) => a.sort_order - b.sort_order)
  speakersSorted?.sort((a, b) => a.sort_order - b.sort_order)

  return (
        <>
            {chairsSorted.length > 0 &&
                chair === 'placeholder_text' &&
                <PlaceholderText
                    slug={slug}
                    viewAllCount={viewAllCountChair}
                    title="Chair"
                    setViewAllCount={setViewAllCountChair}
                    eventId={eventId}
                    people={chairsSorted}
                />
            }
          {speakersSorted.length > 0 &&
              speakerView === 'placeholder_text' &&
              <PlaceholderText
                  slug={slug}
                  viewAllCount={viewAllCount}
                  title="Speakers"
                  setViewAllCount={setViewAllCount}
                  eventId={eventId}
                  people={speakersSorted}
              />
          }
            {
                chair === 'avatar_text' && chairsSorted?.length > 0 &&
                <AvatarText
                    viewAllCount={viewAllCountChair}
                    title="Chair"
                    setViewAllCount={setViewAllCountChair}
                    eventId={eventId}
                    people={chairsSorted}
                    slug={slug}

                />
            }
          {speakerView === 'avatar_text' && speakersSorted.length > 0 &&
              <AvatarText
                  viewAllCount={viewAllCount}
                  title="Speakers"
                  setViewAllCount={setViewAllCount}
                  eventId={eventId}
                  people={speakersSorted}
                  slug={slug}

              />
          }
            {
                chair === 'avatar' && chairsSorted?.length > 0 &&
                <AvatarView
                    viewAllCount={viewAllCountChair}
                    title='Chair'
                    people={chairsSorted}
                    slug={slug}
                    eventId={eventId}
                    setViewAllCount={setViewAllCountChair}
                />

            }
          {
              speakerView === 'avatar' && speakersSorted.length > 0 &&
              <AvatarView
                  viewAllCount={viewAllCount}
                  title='Speakers'
                  people={speakersSorted}
                  slug={slug}
                  eventId={eventId}
                  setViewAllCount={setViewAllCount}
              />

          }
            { chair === 'text' && chairsSorted?.length > 0 &&
                <TextView
                    setViewAllCount={setViewAllCountChair}
                    eventId={eventId}
                    people={chairsSorted}
                    slug={slug}
                    viewAllCount={viewAllCountChair}
                    title="Chair"
                />
            }
            {speakersSorted.length > 0 && speakerView === 'text' &&
                <TextView
                  setViewAllCount={setViewAllCount}
                  eventId={eventId}
                  people={speakersSorted}
                  slug={slug}
                  viewAllCount={viewAllCount}
                  title="Speakers"
                />
            }

        </>
  )
}

export default SpeakersAndChairsDetail
