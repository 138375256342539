import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addRefreshTokenExpiration } from '../globalValue/globalValue'

const initialState = {
  token: '',
  refreshToken: '',
  user: {},
  userId: 0,
  access_token_expiration: ''

}

export const signOut = createAsyncThunk(
  'authSlice/signOut',
  async (_, { dispatch }) => {
    try {
      localStorage.clear()
      dispatch(addRefreshTokenExpiration('expired'))
      dispatch(addUserId(0))
      dispatch(addToken(''))
    } catch (e) {
    }
  }

)

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    addToken: (state, action) => {
      state.token = action.payload
    },
    addRefreshToken: (state, action) => {
      state.refreshToken = action.payload
    },
    addUser: (state, action) => {
      state.user = action.payload
    },
    addUserId: (state, action) => {
      state.userId = action.payload
    },
    addAccessTokenExpiration: (state, action) => {
      state.access_token_expiration = action.payload
    }

  }
})

export const authReducer = authSlice.reducer
export const {
  addToken, addRefreshToken, addUser, addUserId, addAccessTokenExpiration
} = authSlice.actions
