import React from 'react'
import { Button } from '@mui/material'
import { DeleteMessage } from '../../../../SendBird/action'

const ButtonMsg = ({ text, icon, callback, channel, msg }) => {
  return (
      <Button className="msg-detail-div"
                sx={{ justifyContent: 'space-between' }}
              onClick={() => {
                msg ? DeleteMessage(channel, msg) : callback()
                callback()
              }}
      >
          {text ? <p>
              {text}
          </p> : ''}
          {icon || ''}
      </Button>
  )
}

export default ButtonMsg
