import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'

export const useSendMessage = () => {
  const [updateMsg, setUpdateMsg] = useState(false)
  const [msgToSend, setMsgToSend] = useState('')
  const [refreshMessage, setRefreshMessage] = useState(false)
  const [showEmoji, setShowEmoji] = useState(false)
  const [msgToCheck, setMsgToCheck] = useState('')
  const [channel, setChannel] = useState()
  const [msgId, setMsgId] = useState()
  const [isSend, setIsSend] = useState(false)
  const [file, setFile] = useState()
  const [scrollBottom, setScrollBottom] = useState(false)
  const [loading, setLoading] = useState(false)
  const resetAllValueInput = ({ success }) => {
    if (success) setRefreshMessage(!refreshMessage)
    setMsgToSend('')
    setUpdateMsg(false)
    setShowEmoji(false)
  }
  const sendUpdateMessage = async () => {
    const params = {
      message: msgToSend.trim()
    }

    if (params.message !== msgToCheck) {
      const refreshUpdate = await channel?.updateUserMessage(msgId, params)
      if (refreshUpdate.updatedAt) {
        resetAllValueInput({ success: true })
      }
    } else {
      resetAllValueInput({ success: false })
    }
  }

  const sendMessageWithFile = async () => {
    const params = {
      file,
      fileName: file.name,
      fileSize: file.size
    }
    await channel?.sendFileMessage(params)

      .onSucceeded(() => {
        setRefreshMessage(!refreshMessage)
        setFile('')
        setScrollBottom(true)
        setShowEmoji(false)
      })
  }

  const sendTextMessage = async () => {
    const params = { // UserMessageCreateParams can be imported from @sendbird/chat/message.
      message: msgToSend.trim()
    }
    channel?.sendUserMessage(params)
      .onFailed(() => {
        // console.log('Handle error', err, message)
        toast(<FormattedMessage id="toast-message-freeze-chat" defaultMessage="Sending messages is currently disabled"/>, {
          position: 'top-right'
        })
      })

      .onSucceeded((message) => {
        // The message is successfully sent to the channel.
        // The current user can receive messages from other users through the onMessageReceived() method of an event handler.
        setRefreshMessage(!refreshMessage)
        setMsgToSend('')
        setFile(false)

        setScrollBottom(true)
        setShowEmoji(false)
        setTimeout(() => { setLoading(true) }, 500)
      })
  }
  const sendTextMessageAndFile = async () => {
    const paramsFile = {
      file,
      fileName: file.name,
      fileSize: file.size
    }
    await channel?.sendFileMessage(paramsFile)
      .onSucceeded(async () => {
        setFile(false)

        const paramsMsg = { // UserMessageCreateParams can be imported from @sendbird/chat/message.
          message: msgToSend.trim()
        }
        await channel?.sendUserMessage(paramsMsg)

          .onSucceeded(() => {
            setRefreshMessage(!refreshMessage)
            setMsgToSend('')
            setScrollBottom(true)
            setShowEmoji(false)
            setFile(false)
          })
      })
  }
  const sendMessageToSendbird = async ({ file }) => {
    if (updateMsg) {
      sendUpdateMessage()
    }
    if (!!file && msgToSend.trim().length === 0 && !updateMsg) {
      sendMessageWithFile()
    }
    if (msgToSend.trim().length > 0 && !updateMsg && !file) {
      sendTextMessage()
    }
    if (!!file && msgToSend.trim().length > 0 && !updateMsg) {
      sendTextMessageAndFile()
    }
    setIsSend(false)
  }
  useEffect(() => {
    if (isSend) {
      sendMessageToSendbird({ file })
    }
  }, [isSend])
  return {
    setUpdateMsg,
    updateMsg,
    msgToSend,
    setMsgToSend,
    refreshMessage,
    setRefreshMessage,
    showEmoji,
    setShowEmoji,
    msgToCheck,
    setMsgToCheck,
    channel,
    setChannel,
    setMsgId,
    msgId,
    setIsSend,
    setFile,
    file,
    scrollBottom,
    setScrollBottom,
    loading,
    setLoading
  }
}
