import React from 'react'
import { FormattedMessage } from 'react-intl'
import parse from 'html-react-parser'
import { useSelector } from 'react-redux'
import { useGetPosterDetailQuery } from '../../../redux/api/posterApi/posterApi'
import Attachments from '../../../components/Attachments/Attachmets'
import AsqBtnComponent from '../../../components/Buttons/AsqBtn/AsqBtn'

const PosterDetailMobile = () => {
  // url
  const posterId = window.location.href.split('/').slice(-3)[0].toString()

  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)

  // api
  const { data: posterDetail } = useGetPosterDetailQuery({ eventId, posterId })

  return (
        <div className="mobile-desc">
            {posterDetail?.body && <div className="mobile-desc-p">
                {posterDetail?.body && posterDetail?.body.map((desc, index) => {
                  return (
                        <div key={index}>{parse(desc?.value)}</div>
                  )
                })}
            </div>}
            <div>
                <Attachments event={posterDetail}/>
                <div className="qsk-container-mobile">
                    <p className="qsk-container-mobile__desc"><span className="span-poster-desc"><strong><FormattedMessage
                        defaultMessage="Post a question to the author "
                        id="post-question-text"/></strong></span>
                        <br/>
                        <br/>
                        <FormattedMessage
                            id="question-text"
                            defaultMessage="Do you want to ask an author a question? Fell free to pose your question"/>
                    </p>
                        <AsqBtnComponent id={posterDetail?.id} referrer='poster' question_type='question_poster'/>
                </div>
            </div>

        </div>
  )
}
export default PosterDetailMobile
