import { defaultApi } from '../splitApi'

export const askQuestionApi = defaultApi.injectEndpoints({
  tagTypes: ['Question'],
  endpoints: (build) => ({
    getQuestionsList: build.query({
      query: (eventId) => `base/events/${eventId}/questions/audience/`,
      transformResponse: (response) => {
        return response.items
      },
      providesTags: ['Question']

    }),
    postQuestion: build.mutation({
      query: ({ eventId, body }) => ({
        url: `base/events/${eventId}/questions/`,
        body,
        method: 'POST'
      }),
      invalidatesTags: ['Question']
    }),
    deleteQuestion: build.mutation({
      query: ({ eventId, deleteId }) => ({
        url: `base/events/${eventId}/questions/${deleteId}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Question']
    })
  })
})
export const { useGetQuestionsListQuery, usePostQuestionMutation, useDeleteQuestionMutation } = askQuestionApi
