import React from 'react'

const Global = ({ color, width, height }) => {
  return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.00008 12.8334C10.2217 12.8334 12.8334 10.2217 12.8334 7.00008C12.8334 3.77842 10.2217 1.16675 7.00008 1.16675C3.77842 1.16675 1.16675 3.77842 1.16675 7.00008C1.16675 10.2217 3.77842 12.8334 7.00008 12.8334Z"
                stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.66652 1.75H5.24985C4.11235 5.15667 4.11235 8.84333 5.24985 12.25H4.66652" stroke={color}
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.75 1.75C9.8875 5.15667 9.8875 8.84333 8.75 12.25" stroke={color} strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M1.75 9.33333V8.75C5.15667 9.8875 8.84333 9.8875 12.25 8.75V9.33333" stroke={color}
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.75 5.24985C5.15667 4.11235 8.84333 4.11235 12.25 5.24985" stroke={color}
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

  )
}

export default Global
