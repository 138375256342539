export const GetAgendaFilterAPI = async (day_id, agendaDays, eventId, agendaEvents) => {
  for (const nextDay of agendaDays) {
    if (+nextDay.date.split('-').slice(-1)[0] === +new Date().getDate() && +nextDay.id === +day_id) {
      const event = agendaEvents?.find((event) => {
        const year = +event.day.split('-')[0]
        const month = +event.day.split('-')[1]
        const day = +event.day.split('-')[2]
        const starHour = +event.start.split(':')[0]
        const starMinutes = +event.start.split(':')[1]
        const nowTimeSeconds = new Date(new Date())
        const startTimeSeconds = new Date(year, month - 1, day, starHour, starMinutes)
        if (startTimeSeconds - nowTimeSeconds > 0) {
          return event
        }
      })
      if (!event) {
        return { skip: true }
      } else {
        return event
      }
    }
  }
}
