import React from 'react'

const SponsorAgenda = ({ event }) => {
  return (

        <div className="sponsor-img-div sponsors-agenda ">
            <img className="pointer"
                 onClick={() => {
                   const element = document.createElement('a')
                   element.href = event?.sponsor?.url
                   element.setAttribute('target', '_blank')
                   document.body.appendChild(element)
                   element.click()
                 }}
                 src={event?.sponsor?.download_url}
                 alt={event?.sponsor?.title}/>
        </div>
  )
}

export default SponsorAgenda
