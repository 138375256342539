import React from 'react'

const Document = () => {
  return (
        <svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.4154 20.4168V30.6252C45.4154 40.8335 41.332 44.9168 31.1237 44.9168H18.8737C8.66537 44.9168 4.58203 40.8335 4.58203 30.6252V18.3752C4.58203 8.16683 8.66537 4.0835 18.8737 4.0835H29.082M45.4154 20.4168L37.2487 20.4168C31.1237 20.4168 29.082 18.3752 29.082 12.2502L29.082 4.0835M45.4154 20.4168L29.082 4.0835" stroke="#282828" strokeOpacity="0.08" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

  )
}

export default Document
