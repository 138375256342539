import { connectToSB, disconnect } from '../../pages/InboxPage/SendBird/action'
import { addRefreshTokenExpiration } from '../../redux/slices/globalValue/globalValue'
import { addRefreshToken, addToken, addUserId } from '../../redux/slices/authSlice/authSlice'

export const handleLogout = async ({ dispatch }) => {
  try {
    const sb = await connectToSB()
    await disconnect(sb)
  } catch (e) {}
  localStorage.clear()
  dispatch(addRefreshTokenExpiration(''))
  dispatch(addUserId(0))
  dispatch(addToken(''))
  dispatch(addRefreshToken(''))
}
