import React from 'react'
import Picker from 'emoji-picker-react'

const Emoji = ({ updateMsg, setChosenEmoji }) => {
  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject)
  }
  return (
        <>
            {
                updateMsg
                  ? <div className="emoji-div-edit">
                        <Picker onEmojiClick={onEmojiClick}/>
                    </div>

                  : <div className="emoji-div">
                        <Picker onEmojiClick={onEmojiClick}/>
                    </div>
            }
        </>
  )
}

export default React.memo(Emoji)
