import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { agendaReducer } from '../slices/agendaSlice/agendaSlice'
import { persistReducer, persistStore } from 'redux-persist'
import { defaultApi } from '../api/splitApi'
import storage from 'redux-persist/lib/storage'
import { authReducer } from '../slices/authSlice/authSlice'
import { globalValueReducer } from '../slices/globalValue/globalValue'
import { participantReducer } from '../slices/participantSlice/participantSlice'
import { sendBirdSliceReducer } from '../slices/SendBirdSlice/SendBirdSlice'

const rootReducer = combineReducers({
  [defaultApi.reducerPath]: defaultApi.reducer,
  authReducer,
  agendaReducer,
  participantReducer,
  sendBirdSliceReducer,
  globalValueReducer
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['authReducer', 'globalValueReducer']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(
      {
        serializableCheck: false
      }
    )
      .concat(defaultApi.middleware)
})
export const persistor = persistStore(store)
export default store
