import React from 'react'
import colors from '../../Colors.scss'

const IsOnlineAgenda = ({ width = '116', height = '34', colorText = 'white' }) => {
  return (
        <svg width={width} height={height} viewBox="0 0 116 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="116" height="33" rx="16.5" fill={colors.color_gradient}/>
            <circle cx="20" cy="17" r="4" fill={colorText}/>
            <path d="M31.0664 11.4238H33.1719V19.6885H38.1621V21.5H31.0664V11.4238ZM41.5527 21.5H39.4609V11.4238H41.5527V21.5ZM49.4209 11.4238H51.6084L48.1699 21.5H46.1807L42.7764 11.4238H45.0254L47.2129 19.0732L49.4209 11.4238ZM60.3105 13.208H54.9785V15.3477H59.873V17.0977H54.9785V19.6885H60.5566V21.5H52.9209V11.4238H60.3105V13.208ZM66.0596 11.4238H68.2676L72.2803 18.458V11.4238H74.2422V21.5H72.1367L68.0215 14.3428V21.5H66.0596V11.4238ZM80.668 19.9961C81.502 19.9961 82.1628 19.6885 82.6504 19.0732C83.1426 18.458 83.3887 17.583 83.3887 16.4482C83.3887 15.318 83.1426 14.4453 82.6504 13.8301C82.1628 13.2103 81.502 12.9004 80.668 12.9004C79.834 12.9004 79.1686 13.208 78.6719 13.8232C78.1751 14.4385 77.9268 15.3135 77.9268 16.4482C77.9268 17.583 78.1751 18.458 78.6719 19.0732C79.1686 19.6885 79.834 19.9961 80.668 19.9961ZM85.4941 16.4482C85.4941 18.2575 84.986 19.6429 83.9697 20.6045C83.2087 21.3883 82.1081 21.7803 80.668 21.7803C79.2279 21.7803 78.1273 21.3883 77.3662 20.6045C76.3454 19.6429 75.835 18.2575 75.835 16.4482C75.835 14.6025 76.3454 13.2171 77.3662 12.292C78.1273 11.5081 79.2279 11.1162 80.668 11.1162C82.1081 11.1162 83.2087 11.5081 83.9697 12.292C84.986 13.2171 85.4941 14.6025 85.4941 16.4482ZM88.4814 11.4238L89.8145 17.1934L90.1016 18.7998L90.3955 17.2275L91.5303 11.4238H93.752L94.9482 17.1934L95.2559 18.7998L95.5635 17.2549L96.9102 11.4238H99.0498L96.2129 21.5H94.2031L92.9863 15.6074L92.6309 13.6592L92.2754 15.6074L91.0586 21.5H89.1035L86.2461 11.4238H88.4814Z" fill="white"/>
            <defs>
                <linearGradient id="paint0_linear_1992_17187" x1="0" y1="0.5" x2="117.563" y2="7.59558" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF68C3"/>
                    <stop offset="0.296875" stopColor="#FF7474"/>
                    <stop offset="1" stopColor="#5771F9"/>
                </linearGradient>
            </defs>
        </svg>

  )
}

export default IsOnlineAgenda
