import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow
} from '@mui/material'
import Paper from '@mui/material/Paper'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { FormattedMessage, useIntl } from 'react-intl'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

// rsc
import Presence from '../../../components/Presence/Presence'
import ChatIcon from '../../../SVG/global/ChatIcon'
import { useGetCountriesQuery, useGetSortedAttendeesQuery } from '../../../redux/api/participtantApi/participantApi'
import colors from '../../../Colors.scss'
import SearchIcon from '../../../SVG/global/SearchIcon'
import { FilterMenu } from '../../../components/FillterMui/FillterMenu'
import { fillterMenu } from '../modalValue'
import ExitBtn from '../../../SVG/global/ExitBtn'
import { useNavigate } from 'react-router-dom'
import { debounce } from '../../../helper/Debounce'
import Flags from '../Flags'
import Avatar from '../../../components/Avatart/Avatar'
import { useGetVisibleDetailsQuery } from '../../../redux/api/agendaApi/agendaApi'
import PeopleTableIcon from '../../../SVG/pages/participant/peopleTableIcon'
import { useGlobalValue } from '../../../hooks/redux/reduxHooks'
import { useDispatch, useSelector } from 'react-redux'
import { addSortParticipantTable } from '../../../redux/slices/globalValue/globalValue'

const updateParticipants = process.env.REACT_APP_TIME_FOR_UPDATE_PARTICIPANTS_S
export const TableRender = ({ setShow, show }) => {
  // hooks
  const intl = useIntl()
  const navigate = useNavigate()
  const inputTableRef = useRef()
  const dispatch = useDispatch()

  // reducer
  const { eventId, participantSortingTable: orderByTable, slug } = useGlobalValue()
  // state
  const [participantsTable, setParticipantsTable] = useState([])
  const [loaderTable, setLoaderTable] = useState(false)
  const [participantsCount, setParticipantsCount] = useState(0)
  const [orderTable, setOrderTable] = useState('')
  const [anchorElTable, setAnchorElTable] = useState(null)
  // tableState
  const pages = [10, 50, 100]
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [searchValueTable, setSearchValueTable] = useState('')
  const [offsetTable, setOffsetTable] = useState(0)
  const [limit, setLimit] = useState(rowsPerPage)
  const [timePoll, setTimePoll] = useState(0)
  const timeReq = useSelector(state => state.participantReducer.timeReq)
  // api
  const { data: attendeesTableQuery, isLoading: isLoadingTable, refetch, isFetching: isFetchingTable } = useGetSortedAttendeesQuery({ eventId, orderByTable, orderTable, searchValueTable, limit, offsetTable }, {
    pollingInterval: timePoll
  })
  const { data: countries } = useGetCountriesQuery()
  const { data: allowedProperty } = useGetVisibleDetailsQuery(eventId)
  const getSortedAttendeesAPITable = async () => {
    setLoaderTable(true)
    const attendees = attendeesTableQuery?.items || []
    setParticipantsCount(attendeesTableQuery?.meta?.total_count || 0)
    setParticipantsTable([...new Set(attendees)])
    setLoaderTable(false)
  }

  useEffect(() => {
    const time = +(+(timeReq + +(updateParticipants + '000')) - new Date().getTime())
    if (time <= 0) {
      refetch()
      setTimePoll(+(updateParticipants + '000'))
    } else {
      setTimePoll(+time)
    }
  }, [timeReq])

  useEffect(() => {
    if (attendeesTableQuery && !participantsTable.length) {
      getSortedAttendeesAPITable()
    } else if (!isLoadingTable && !isFetchingTable) {
      getSortedAttendeesAPITable()
    }
  }, [attendeesTableQuery, isLoadingTable, isFetchingTable])

  const handleSortRequestTable = (cellId) => {
    const isAsc = orderByTable === cellId && orderTable === ''
    setOrderTable(isAsc ? '-' : '')
    setPage(0)
    setOffsetTable(0)
  }
  const handleFilterMenuTable = (e) => {
    handleSortRequestTable(e.label)
    dispatch(addSortParticipantTable(e.label))
    setPage(0)
    setOffsetTable(0)
  }

  const updateSearchValue = useCallback(
    debounce((value) => {
      setSearchValueTable(value.value)
      setOffsetTable(0)
      setPage(0)
    }, 1000)
    , [])
  const handleSearchTable = async (e) => {
    updateSearchValue({ value: e.target.value })
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setLimit(parseInt(event.target.value, 10))
    setOffsetTable(0)
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    const arrow = event.target.dataset.testid
    if (arrow === 'KeyboardArrowRightIcon') {
      setLimit(rowsPerPage)
      setPage(newPage)
      setLoaderTable(true)
      setOffsetTable(offsetTable + rowsPerPage)
    } else {
      if (arrow && arrow === 'KeyboardArrowLeftIcon') {
        setLimit(rowsPerPage)
        setOffsetTable(offsetTable - rowsPerPage)
        setPage(newPage)
        setLoaderTable(true)
      }
    }
  }
  return (

      <Dialog
          open={show}
          onClose={() => {
            setShow(!show)
          }}
          maxWidth="80%"
          sx={{
            height: '80vh',
            margin: 'auto',
            width: '100vw'

          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="table-main"
      >
          <DialogTitle id="alert-dialog-title"
                       sx={{
                         display: 'flex',
                         justifyContent: 'left',
                         alignItems: 'center',
                         background: `${colors.color_black_50_rgba}`
                       }}
          >
              <p className="table-people__icon">
                  <PeopleTableIcon/>
              </p>
              <p className="participant-title-table">
                  <FormattedMessage id="participants-list" defaultMessage="Participants list"/></p>

              {!loaderTable &&
                  <>
                      {participantsCount > 0 && <div className="people-count-participant">
                          {participantsCount > 0 ? participantsCount + ' people' : ''}
                      </div>}
                      <TextField

                          sx={{
                            '& label': { paddingLeft: (theme) => theme.spacing(1) },
                            '& input': { paddingLeft: (theme) => theme.spacing(1) },
                            '& .MuiInputBase-input': {
                              height: 'auto'
                            },
                            '& fieldset': {
                              paddingLeft: (theme) => theme.spacing(2),
                              borderRadius: '8px'

                            }
                          }}
                          style={{
                            width: '300px',
                            background: colors.color_cards_background,
                            borderRadius: '8px'
                          }}
                          size="small"
                          type="text"
                          value={inputTableRef?.current?.value}
                          ref={inputTableRef}
                          placeholder={`${intl.formatMessage({
                              defaultMessage: 'Search for',
                              id: 'search-for'
                          })}`}
                          onChange={(e) => {
                            handleSearchTable(e)
                          }}

                          InputProps={{
                            startAdornment: (
                                  <InputAdornment position="end">
                                      <SearchIcon/>
                                  </InputAdornment>
                            )
                          }}
                          variant="outlined"
                      />
                      <FilterMenu anchor={anchorElTable} setAnchor={setAnchorElTable} fillterMenu={fillterMenu}
                                  order={orderTable}
                                  anchorName="simple-popover-table"
                                  setOrder={setOrderTable} setOffsetTable={setOffsetTable}
                                  handleFilterMenu={handleFilterMenuTable}
                                  setPage={setPage}
                                  orderBy={orderByTable}
                      />
                  </>
              }
          </DialogTitle>
          <DialogContent
              component={Paper}
              sx={{
                boxSizing: 'border-box',
                width: '1000px',
                padding: '0'
              }}
          >
              <TableContainer
                              align='center'
                              sx={{
                                border: 'none',
                                minHeight: '582px',
                                height: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                              }}>
                  {
                      !loaderTable && !isLoadingTable && !isFetchingTable

                        ? <Table
                          >
                              <TableBody
                              >
                                  {participantsTable.map((row) => {
                                    const countryUser = countries?.find((country) => {
                                      if (country?.value === row?.people?.country) {
                                        return country
                                      }
                                    })
                                    return (
                                          <TableRow
                                              key={row?.people?.sendbird_user_id}
                                              sx={{
                                                '&:last-child td, &:last-child th': { border: 0 }
                                              }}
                                          >
                                              <TableCell
                                                  sx={{
                                                    width: 'fit-content',
                                                    minWidth: '350px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px'
                                                  }}
                                              >
                                                  {allowedProperty?.avatar_enabled ? <Avatar url={row?.people?.avatar?.download_url}
                                                                                            name={row?.people?.full_name}
                                                    id={row?.people?.id} status_online={row?.people?.status_online === 'ONLINE'}
                                                  /> : ''}
                                                  <div className="table-participant-full-name-presence-container">

                                                      <div className="table-participant-full-name">
                                                          {allowedProperty?.full_name_enabled ? <div>
                                                              {row?.people?.full_name && row?.people?.full_name}
                                                          </div> : ''}
                                                         <div className="table-participant-country">
                                                              {allowedProperty?.country_enabled && <span>{countryUser?.label && countryUser?.label}</span>}
                                                              <Flags abbreviation={row?.people?.country} show={allowedProperty?.country_flag_enabled}/>
                                                          </div>
                                                      </div>
                                                      <div className="table-participant-presence">
                                                          <Presence status={row?.people?.attendee_type}/>
                                                      </div>
                                                  </div>

                                              </TableCell>
                                              {/* <TableCell> */}
                                              {/*    {row?.people?.institution ? row?.people?.institution : ''} */}
                                              {/* </TableCell> */}
                                              {show && (
                                                  <>
                                                      <TableCell
                                                          sx={{
                                                            width: '100%'
                                                          }}
                                                      >
                                                          <div className="table-participant-job">
                                                              <span style={{ color: ' rgba(40, 40, 40, 0.5)' }} >{(allowedProperty?.job_title_enabled && row?.people?.job_title) ? row?.people?.job_title : ''}</span>
                                                              <span style={{ color: ' rgba(40, 40, 40, 0.5)' }} >{(allowedProperty?.job_title_enabled && allowedProperty?.institution_enabled && row?.people?.institution) && row?.people?.job_title ? ' at' : ''}</span>
                                                              <strong> {(allowedProperty?.institution_enabled && row?.people?.institution) && row?.people?.institution }</strong>
                                                          </div>
                                                      </TableCell>
                                                      <TableCell
                                                          sx={{
                                                            paddingRight: '45px'
                                                          }}

                                                      >
                                                          <div
                                                              onClick={() =>
                                                                navigate(`/events/${eventId}/${slug}/participants/chat/${row?.people?.id}`)
                                                              }
                                                              className="pointer"
                                                          >
                                                              <ChatIcon/>
                                                          </div>
                                                      </TableCell>
                                                      {/* <TableCell> */}
                                                      {/*    <div */}
                                                      {/*        className="pointer"> */}

                                                      {/*        <Camera/> */}
                                                      {/*    </div> */}
                                                      {/* </TableCell> */}
                                                  </>
                                              )}
                                          </TableRow>
                                    )
                                  })}
                              </TableBody>
                          </Table>
                        : <CircularProgress className="circularProgress"/>
                  }
              </TableContainer>
              <TableContainer>
                  <Table>
                      <TableFooter>
                          <TableRow>
                              <TablePagination
                                  page={page}
                                  rowsPerPageOptions={pages}
                                  rowsPerPage={rowsPerPage}
                                  count={participantsCount}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                          </TableRow>
                      </TableFooter>
                  </Table>
              </TableContainer>
          </DialogContent>
          <Button sx={{
            position: 'absolute',
            top: '16px',
            right: '30px',
            ' &:hover': {
              background: 'none'
            }
          }}
                  onClick={() => {
                    setShow(!show)
                    setSearchValueTable('')
                  }}>
              <ExitBtn colorPath={colors.color_black}
                       colorCircle={colors.color_black}
                       width='36'
                       height='36'
                       fillOpacity='0.08'
              />
          </Button>
      </Dialog>

  )
}
