import React from 'react'
import Global from '../../SVG/Presence/Global'
import OnSiteStatus from '../../SVG/Presence/OnSiteStatus'
import { FormattedMessage } from 'react-intl'
import colors from '../../Colors.scss'

const Presence = ({ status }) => {
  return (
        <div className="presence-main">
            <div className="presence-main__centred">{status !== 'IN_PERSON' ? <Global color={colors.color_in_person} width="14" height="14"/> : <OnSiteStatus width={12} height={12} color={colors.color_on_site}/>}</div>
            <div><p>{status !== 'IN_PERSON' ? <FormattedMessage id='remote' defaultMessage='Remote'/> : <FormattedMessage id='on-site' defaultMessage='On-site'/>}</p></div>
        </div>
  )
}

export default Presence
