import React, { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Popover from '@mui/material/Popover'
import FilterListIcon from '@mui/icons-material/FilterList'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { useSelector } from 'react-redux'

// rsc
import './Posters.scss'
import { debounce } from '../../helper/Debounce'
import SearchIcon from '../../SVG/global/SearchIcon'
import PosterDetailMobile from './PostersDetails/PosterDetailMobile'
import colors from '../../Colors.scss'
import PostersSvg from '../../SVG/pages/Poster/PostersSvg'
import Questions from '../../SVG/pages/question/Questions'
import ContextHelp from '../../components/ContextHelp/ContextHelp'
import { useGetPostersQuery } from '../../redux/api/posterApi/posterApi'
import { fillterMenu } from './FilterMenu'

const Posters = () => {
  // hooks
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  // url
  const isPosterDetail = (window.location.href.includes('posters') && window.location.href.includes('details'))
  const posterId = location.pathname.split('/').slice(-3)[0]

  // redux
  const slug = useSelector(state => state.globalValueReducer.slug)
  const eventId = useSelector(state => state.globalValueReducer.eventId)

  // state
  const [char, setChar] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [order, setOrder] = useState('')
  const [orderBy, setOrderBy] = useState('title')
  const [limit, setLimit] = useState(1000)
  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })

  // api
  const { data: posters, refetch } = useGetPostersQuery({ eventId, searchValue, orderBy, order, limit })

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === ''
    setOrder(isAsc ? '-' : '')
    setOrderBy(cellId)
    refetch()
  }

  const handleFilterMenu = (e) => {
    handleSortRequest(e.label)
  }

  const updateSearchValue = useCallback(
    debounce((str) => {
      setSearchValue(str)
    }, 1000)
    , [])

  const handleSearch = async (e) => {
    setChar(e.target.value)
    updateSearchValue(e.target.value)
  }
  return (
        <div className="poster-wrap">
            <div className="title-main">
                <div className="title relative">
                  <PostersSvg width='30' height='30'/><p className="svg-title"><FormattedMessage id="posters" defaultMessage="Posters"/>
                  <span
                        onClick={(e) => {
                          setShowModalHelper({
                            open: !showModalHelper.open,
                            event: e.currentTarget
                          })
                        }}>
                    <Questions/>
                    {showModalHelper && <ContextHelp helpText={{ id: 1, what: <FormattedMessage id='posters-helper' defaultMessage='You can read, watch accompanying videos, give input and ask questions to the authors.' /> }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>}
                  </span>
                    </p>
                </div>
            </div>
            <div className="search">
                {window.screen.width > 1024
                  ? <>
                        <TextField

                            sx={{
                              '& label': { paddingLeft: (theme) => theme.spacing(1) },
                              '& input': { paddingLeft: (theme) => theme.spacing(1) },
                              '& fieldset': {
                                paddingLeft: (theme) => theme.spacing(2),
                                borderRadius: '30px'

                              },
                              width: '300px',
                              background: colors.color_cards_background,
                              borderRadius: '30px',
                              marginBottom: '20px'
                            }}

                            size="small"
                            margin="normal"
                            type="text"
                            value={char}
                            placeholder={`${intl.formatMessage({
                                defaultMessage: 'Search',
                                id: 'search'
                            })}`}
                            onChange={(e) => {
                              handleSearch(e)
                            }}

                            InputProps={{
                              startAdornment: (
                                    <InputAdornment position="end"
                                    >
                                        <SearchIcon/>
                                    </InputAdornment>
                              )

                            }}
                            variant="outlined"
                        />
                        <div>
                            <div className="participants-filter">
                                <Popover
                                    open={Boolean(anchorEl)}
                                    id={anchorEl ? 'simple-popover-posters' : undefined}
                                    onClose={() => setAnchorEl(null)}
                                    onClick={() => {
                                      setAnchorEl(!anchorEl)
                                    }}
                                    anchorOrigin={{
                                      vertical: 260,
                                      horizontal: 'right'
                                    }}
                                    // transformOrigin={{
                                    //     vertical: "top",
                                    //     horizontal: "center"
                                    // }}
                                >
                                    <p className="filter-menu-head"><FormattedMessage id="sort-by" defaultMessage="Sort by"/></p>
                                    {fillterMenu?.map((menu) => (
                                        <p
                                            key={menu?.id}
                                            className="filter-menu"
                                            onClick={() => {
                                              handleFilterMenu(menu)
                                              setAnchorEl(null)
                                            }}
                                        >
                                            {menu?.name}
                                        </p>
                                    ))}

                                </Popover>
                                <FilterListIcon
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                    fontSize="medium"
                                />

                                {

                                    order === ''
                                      ? <ArrowDownwardIcon
                                            onClick={() => {
                                              setOrder('-')
                                              refetch()
                                            }}/>
                                      : <ArrowUpwardIcon
                                            onClick={() => {
                                              setOrder('')
                                              refetch()
                                            }}/>
                                }
                            </div>
                        </div>
                    </>
                  : <>
                        <TextField

                            sx={{
                              '& label': { paddingLeft: (theme) => theme.spacing(1) },
                              '& input': {
                                paddingLeft: (theme) => theme.spacing(1),
                                zIndex: '2',
                                fontFamily: 'Inter',
                                fontWeight: '500',
                                fontSize: '14px',
                                lineHeight: '17px'
                              },
                              '& fieldset': {
                                paddingLeft: (theme) => theme.spacing(2),
                                borderRadius: '30px'

                              },
                              width: '100%',
                              background: colors.color_cards_background,
                              borderRadius: '30px',
                              marginBottom: '20px'
                            }}

                            size="small"
                            margin="normal"
                            type="text"
                            value={char}
                            placeholder={`${intl.formatMessage({
                                defaultMessage: 'Search Posters',
                                id: 'search-posters'
                            })}`}
                            onChange={(e) => {
                              handleSearch(e)
                            }}

                            InputProps={{
                              startAdornment: (
                                    <InputAdornment position="end"
                                    >
                                        <SearchIcon/>
                                    </InputAdornment>
                              ),
                              endAdornment: (
                                    <div>
                                        <div className="participants-filter">
                                            <Popover
                                                open={Boolean(anchorEl)}
                                                id={anchorEl ? 'simple-popover-posters' : undefined}
                                                onClose={() => setAnchorEl(null)}
                                                onClick={() => {
                                                  setAnchorEl(!anchorEl)
                                                }}
                                                anchorOrigin={{
                                                  vertical: 260,
                                                  horizontal: 'right'
                                                }}
                                                // transformOrigin={{
                                                //     vertical: "top",
                                                //     horizontal: "center"
                                                // }}
                                            >
                                                <p className="filter-menu-head"><FormattedMessage id="sort-by" defaultMessage="Sort by"/></p>
                                                {fillterMenu?.map((menu) => (
                                                    <p
                                                        key={menu?.id}
                                                        className="filter-menu"
                                                        onClick={() => {
                                                          handleFilterMenu(menu)
                                                          setAnchorEl(null)
                                                        }}
                                                    >
                                                        {menu?.name}
                                                    </p>
                                                ))}

                                            </Popover>
                                            <FilterListIcon
                                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                                fontSize="medium"
                                            />

                                            {

                                                order === ''
                                                  ? <ArrowDownwardIcon
                                                        onClick={() => {
                                                          setOrder('-')
                                                          refetch()
                                                        }}/>
                                                  : <ArrowUpwardIcon
                                                        onClick={() => {
                                                          setOrder('')
                                                          refetch()
                                                        }}/>
                                            }
                                        </div>
                                    </div>
                              )
                            }}
                            variant="outlined"
                        >
                        </TextField>
                      <div
                          onClick={(e) => {
                            setShowModalHelper({
                              open: !showModalHelper.open,
                              event: e.currentTarget
                            })
                          }}>
                              <Questions width='25' height='25' color={colors.color_grey_shade}/>
                              <ContextHelp helpText={{ id: 1, what: <FormattedMessage id='posters-helper' defaultMessage='You can read, watch accompanying videos, give input and ask questions to the authors.' /> }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>

                      </div>
                    </>

                }

            </div>

            <div className={`posters-main ${isPosterDetail && 'posters-main-desc-mobile'}`}>

                {!!posters && posters.items.map((poster) => {
                  const authors = poster.authors.split(';').splice(0, 3).join()
                  return (
                        <div key={poster.id}
                             style={{
                               backgroundColor: +posterId === +poster.id ? colors.highlighted_agenda : colors.color_cards_background,
                               color: +posterId === +poster.id ? colors.color_white : colors.color_black
                             }}
                             className="poster-card">
                            <div
                                className={`${+posterId === +poster.id ? 'navigate-posters-details-active ' : 'navigate-posters-details '}`}
                                onClick={() => {
                                  navigate(`/events/${eventId}/${slug}/posters/${poster.id}/details/`)
                                }}>
                                {!!poster.title && <h4>{poster.title}</h4>}
                                {!!poster.short_description &&
                                    <div className="short_description-posters">
                                        {poster.short_description}
                                    </div>}
                                {!!poster.authors && <div className="poster-authors">
                                    <p className="poster-authors-p"
                                    style={{
                                      color: +posterId === +poster.id ? colors.highlighted_agenda_text_color : colors.color_silver_icons

                                    }}>

                                        {poster.authors.split(';').length > 3 ? <>{authors}<span className={`${+posterId === poster?.id ? 'span-white' : 'span-black'}`}> + {poster.authors.split(';').length - 3} more</span></> : authors }
                                    </p>
                                </div>}

                            </div>
                        </div>
                  )
                })}
            </div>
          {
              isPosterDetail && window.screen.width < 1024 && <PosterDetailMobile/>
          }
        </div>
  )
}

export default Posters
