import React from 'react'

const OnSiteStatus = ({ width, height, color }) => {
  return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.11177 4.95258C3.26093 -0.099084 10.7451 -0.0932506 11.8884 4.95842C12.5593 7.92175 9.84093 11.3051 8.2251 12.8567C7.55352 13.5017 6.42288 13.4867 5.76927 12.8567C4.15927 11.3051 1.44093 7.91592 2.11177 4.95258Z"
                fill={color}
                stroke={color}
                strokeWidth="1.5"
            />
            <path
                d="M6.99993 7.83434C8.00509 7.83434 8.81993 7.01949 8.81993 6.01434C8.81993 5.00918 8.00509 4.19434 6.99993 4.19434C5.99477 4.19434 5.17993 5.00918 5.17993 6.01434C5.17993 7.01949 5.99477 7.83434 6.99993 7.83434Z"
                fill={color}
                stroke="#F8F8F8"
                strokeWidth="1.5"
            />
        </svg>
  )
}

export default OnSiteStatus
