import { countUnreadMessagesGroupChat, getAllChannels } from '../../pages/InboxPage/SendBird/action'

export const getAllCountChannel = async ({ sendBird, userSendBird }) => {
  if (sendBird && userSendBird) {
    try {
      const allChannels = await getAllChannels(sendBird)

      const countUnreadMsg = await countUnreadMessagesGroupChat(sendBird)

      return { allChannels, countUnreadMsg }
    } catch (e) {}
  }
}
