import React from 'react'
import AddReactionIcon from '@mui/icons-material/AddReaction'

const AddReaction = ({ msg, setMsgId, setMsgEmotion, showReactionEmoji }) => {
  return (

        <AddReactionIcon
            onClick={() => {
              setMsgId(msg.messageId)
              setMsgEmotion(msg)
              showReactionEmoji()
            }}

            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              cursor: 'pointer'
            }}
        />

  )
}

export default React.memo(AddReaction)
