import React from 'react'
import colors from '../../../../Colors.scss'

const AddressSVG = ({ width = '22', height = '24', color = colors.color_blue }) => {
  return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.2044 3.51898C10.534 3.51898 9.96373 3.9848 9.80365 4.61265H15.1863C15.0263 3.9848 14.456 3.51898 13.7856 3.51898H11.2044ZM16.7071 4.61264H18.6881C20.7891 4.61264 22.5 6.34428 22.5 8.47085C22.5 8.47085 22.44 9.3711 22.42 10.6248C22.418 10.724 22.3699 10.8212 22.2909 10.88C21.8097 11.2354 21.3694 11.5291 21.3294 11.5493C19.6686 12.6632 17.7386 13.447 15.6826 13.8369C15.5485 13.8632 15.4165 13.7934 15.3484 13.6739C14.7721 12.6754 13.6956 12.0253 12.495 12.0253C11.3024 12.0253 10.2159 12.6683 9.62256 13.6678C9.55353 13.7853 9.42346 13.8531 9.2904 13.8278C7.25138 13.4369 5.32141 12.6541 3.67059 11.5594L2.71011 10.8911C2.63007 10.8405 2.58004 10.7493 2.58004 10.6481C2.55003 10.1316 2.5 8.47085 2.5 8.47085C2.5 6.34428 4.21086 4.61264 6.31191 4.61264H8.28289C8.47299 3.1443 9.7036 2 11.2044 2H13.7856C15.2864 2 16.517 3.1443 16.7071 4.61264ZM22.1598 12.8152L22.1198 12.8355C20.0988 14.1924 17.6676 15.0937 15.1163 15.4684C14.7561 15.519 14.3959 15.2861 14.2959 14.9216C14.0758 14.0912 13.3654 13.5443 12.515 13.5443H12.505H12.485C11.6346 13.5443 10.9242 14.0912 10.7041 14.9216C10.6041 15.2861 10.2439 15.519 9.88369 15.4684C7.33242 15.0937 4.9012 14.1924 2.88019 12.8355C2.87019 12.8254 2.77014 12.7646 2.6901 12.8152C2.60005 12.8659 2.60005 12.9874 2.60005 12.9874L2.67009 18.1519C2.67009 20.2785 4.37094 22 6.47199 22H18.518C20.6191 22 22.3199 20.2785 22.3199 18.1519L22.4 12.9874C22.4 12.9874 22.4 12.8659 22.3099 12.8152C22.2599 12.7849 22.1999 12.795 22.1598 12.8152ZM13.2454 17.0583C13.2454 17.4836 12.9152 17.8177 12.495 17.8177C12.0848 17.8177 11.7446 17.4836 11.7446 17.0583V15.7519C11.7446 15.3367 12.0848 14.9924 12.495 14.9924C12.9152 14.9924 13.2454 15.3367 13.2454 15.7519V17.0583Z"
                fill={color}
            />
        </svg>
  )
}

export default AddressSVG
