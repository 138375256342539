import SendbirdChat, { UserOnlineState } from '@sendbird/chat'
import { OpenChannelModule } from '@sendbird/chat/openChannel'
import { GroupChannelModule } from '@sendbird/chat/groupChannel'

const APPLICATION_ID = process.env.REACT_APP_SENDBIRD_APP_ID
export const connectToSB = async () => {
  const sendBird = await SendbirdChat.init({
    appId: APPLICATION_ID,
    modules: [
      new OpenChannelModule(),
      new GroupChannelModule()
    ]
  })
  return sendBird
}
export const ConnectUserToSendBird = async (profileDetails, sendBird) => {
  // The USER_ID below should be unique to your Sendbird application.
  if (profileDetails && sendBird) {
    try {
      await sendBird?.connect(profileDetails?.sendbird_user_id, profileDetails?.sendbird_access_token)

      const params = {
        nickname: profileDetails?.full_name,
        profileUrl: profileDetails?.avatar?.meta?.download_url
      }
      const userToUpdate = await sendBird?.updateCurrentUserInfo(params)
      // The user is connected to the Sendbird server.
      return userToUpdate
    } catch (err) {
    // Handle error.

      // console.log('Connected failed ', err)
    }
  }
}

export const EditMessage = async (channel, params, msgId) => {
  const updatedMessage = await channel?.updateUserMessage(msgId, params)
  return updatedMessage
  // return updatedMessage
}
export const SendFileMsg = async (channel, params) => {
  const fileMsg = await channel?.sendFileMessage(params)
  return fileMsg
}
export const DeleteMessage = async (channel, msg) => {
  await channel?.deleteMessage(msg)
  return true
}

export const getAllChannels = async (sendBird) => {
  if (sendBird) {
    const params = {
      includeEmpty: true,
      order: 'latest_last_message'
      // limit: 15, // The value of the pagination limit could be set up to 100.

    }
    const query = await sendBird?.groupChannel.createMyGroupChannelListQuery(params)

    if (query?.hasNext) {
      const channels = await query?.next()
      return channels
    }
  }
}

export const previousMessage = async (channel, limit) => {
  try {
    if (channel) {
      const params = {
        limit,
        reverse: false,
        includeReactions: true
      }

      const query = await channel?.createPreviousMessageListQuery(params)

      const messages = await query?.load()

      return messages
    }
  } catch (e) {
    // console.log(e)
  }
}
export const disconnect = async (sendBird) => {
  await sendBird?.disconnect()
  return true
}
export const countUnreadMessagesGroupChat = async (sendBird) => {
  if (sendBird) {
    const count = await sendBird?.groupChannel.getTotalUnreadChannelCount()
    return count
  }
}

export const userIsOnline = async (sendBird, id) => {
  const connectedUser = []
  const queryParams = {
    userIdsFilter: id
  }
  const query = sendBird?.createApplicationUserListQuery(queryParams)

  const user = await query?.next()
  if (user?.length >= 1) {
    for (let i = 0; i <= user.length - 1; i++) {
      if (user[i].connectionStatus === UserOnlineState.ONLINE) {
        connectedUser.push({ connected: true, userId: user[i].userId })
        // Jeff is currently online.

        // Acceptable values for User.connectionStatus are NON_AVAILABLE, ONLINE, and OFFLINE.
        // ...
      }
    }
  }

  if (connectedUser?.length >= 1) {
    return connectedUser
  } else {
    return ['false']
  }
}

export const connectUser = async ({ userDetail, sendBird }) => {
  if (!sendBird) return
  const connected = await sendBird?.connect(userDetail?.sendbird_user_id, userDetail?.sendbird_access_token)
  return connected
}
