import React from 'react'

const ShowEmoji = ({ msg, user, emojiReaction }) => {
  return (
        <>
                    <div
                        className={msg?.sender.userId === user?.userId ? 'my-reaction' : 'not-my-reaction'}>
                        {emojiReaction && emojiReaction?.map((emoji) => {
                          if (emoji?.key === msg?.reactions[0]?.key) {
                            return (
                                    <img key={emoji?.key} style={{ width: '100%' }} src={emoji?.url}
                                         alt={emoji?.key}/>
                            )
                          }
                        })}
                    </div>
        </>
  )
}

export default React.memo(ShowEmoji)
