import {
  connectToSB,
  ConnectUserToSendBird,
  countUnreadMessagesGroupChat,
  disconnect
} from '../../pages/InboxPage/SendBird/action'
import { addSendBird, addUnreadMsgCount, addUserSendBird } from '../../redux/slices/SendBirdSlice/SendBirdSlice'

export const countUnreadMsg = async ({ profileDetailsSB, dispatch }) => {
  const sendBird = await connectToSB()
  const connectedUser = await ConnectUserToSendBird(profileDetailsSB, sendBird)
  if (connectedUser) {
    const countUnreadMsg = await countUnreadMessagesGroupChat(sendBird)
    dispatch(addUnreadMsgCount(+countUnreadMsg))
    await disconnect(sendBird)
    dispatch(addSendBird(null))
  }
}

// env
const refreshMsgTime = process.env.REACT_APP_TIME_FOR_GET_MSG
export const connectSbAndCheckMsgCount = async ({ chatHref, href, profileDetailsSB, sendBird, dispatch }) => {
  if (chatHref === 'chat' || chatHref === 'inbox' || href.includes('hotline')) {
    if (profileDetailsSB) {
      const user = await sendBird?.connect(profileDetailsSB?.sendbird_user_id, profileDetailsSB?.sendbird_access_token)
      if (user) {
        dispatch(addUserSendBird(user))
      }
    }
  } else {
    await countUnreadMsg({ profileDetailsSB, dispatch })
    const intervalConnection = setInterval(() => {
      (async () => {
        if (sendBird) {
          await countUnreadMsg({ profileDetailsSB, dispatch })
        }
      })()
    }, refreshMsgTime)
    return () => {
      clearInterval(intervalConnection)
    }
  }
}
