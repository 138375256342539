import { FormattedMessage } from 'react-intl'
import React from 'react'

export const fillterMenu = [
  // {
  //   id: 1,
  //   label: 'first_name',
  //   name: <FormattedMessage id="first_name" defaultMessage="Name"/>
  // },
  {
    id: 1,
    label: 'title',
    name: <FormattedMessage id="title" defaultMessage="Title"/>
  }

]
