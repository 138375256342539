import React from 'react'
import colors from '../../../Colors.scss'

const PostersSvg = ({ width = '25', height = '24' }) => {
  return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 25 24"
          fill={colors.color_cards_background}
          xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M13.8134 21.5485C14.1431 21.6252 14.1733 22.057 13.8522 22.164L11.9562 22.788C7.19221 24.324 4.68421 23.04 3.13621 18.276L1.60021 13.536C0.0642142 8.772 1.33621 6.252 6.10021 4.716L6.93221 4.44047C7.33507 4.30706 7.72422 4.71 7.60463 5.11718C7.51513 5.42193 7.43042 5.74421 7.34821 6.084L6.17221 11.112C4.85221 16.764 6.78421 19.884 12.4362 21.228L13.8134 21.5485Z"/>
        <path
            d="M19.2039 1.45206L17.1999 0.984059C13.1919 0.0360586 10.8039 0.816058 9.39987 3.72006C9.03987 4.45206 8.75187 5.34006 8.51187 6.36006L7.33587 11.3881C6.15987 16.4041 7.70787 18.8761 12.7119 20.0641L14.7279 20.5441C15.4239 20.7121 16.0719 20.8201 16.6719 20.8681C20.4159 21.2281 22.4079 19.4761 23.4159 15.1441L24.5919 10.1281C25.7679 5.11206 24.2319 2.62806 19.2039 1.45206ZM16.9479 13.5961C16.8399 14.0041 16.4799 14.2681 16.0719 14.2681C15.9999 14.2681 15.9279 14.2561 15.8439 14.2441L12.3519 13.3561C11.8719 13.2361 11.5839 12.7441 11.7039 12.2641C11.8239 11.7841 12.3159 11.4961 12.7959 11.6161L16.2879 12.5041C16.7799 12.6241 17.0679 13.1161 16.9479 13.5961ZM20.4639 9.54006C20.3559 9.94806 19.9959 10.2121 19.5879 10.2121C19.5159 10.2121 19.4439 10.2001 19.3599 10.1881L13.5399 8.71206C13.0599 8.59206 12.7719 8.10006 12.8919 7.62006C13.0119 7.14006 13.5039 6.85206 13.9839 6.97206L19.8039 8.44806C20.2959 8.55606 20.5839 9.04806 20.4639 9.54006Z"/>
        </svg>

  )
}

export default PostersSvg
