import { defaultApi } from '../splitApi'

export const authApi = defaultApi.injectEndpoints({

  endpoints: (build) => ({

    postSignIn: build.mutation({
      query: (credientials) => ({
        url: 'auth/login/',
        method: 'POST',
        body: credientials,
        headers: {}
      })
    }),
    refreshToken: build.mutation({
      query: (refresh_token) => ({
        url: 'auth/token/refresh/',
        method: 'POST',
        body: { refresh_token },
        headers: {}
      })
    }),
    getAttendeeProfile: build.query({
      query: (profileId) => `base/people/${profileId}/`
    }),
    forgotPasswordCall: build.mutation({
      query: ({ email }) => ({
        url: 'auth/password/reset/',
        method: 'POST',
        body: { email }
      })
    }),
    resetPasswordConfirm: build.mutation({
      query: ({ password, uId, token }) => ({
        url: 'auth/password/reset/confirm/',
        method: 'POST',
        body: {
          new_password1: password,
          new_password2: password,
          uid: uId,
          token
        }
      })
    }),

    changePasswordCall: build.mutation({
      query: ({ password1, oldPassword }) => ({
        url: 'auth/password/change/',
        method: 'POST',
        body: {
          new_password1: password1,
          new_password2: password1,
          old_password: oldPassword
        }

      })
    }),
    accountVerify: build.mutation({
      query: ({ uId, token }) => ({
        url: 'auth/user/verified/',
        method: 'POST',
        body: {
          uid: uId,
          token
        }

      })
    }),
    accountConfirm: build.mutation({
      query: ({ uId, token, password, email }) => ({
        url: 'auth/user/confirm/',
        method: 'POST',
        body: {
          new_password1: password,
          new_password2: password,
          uid: uId,
          token,
          email
        }
      })
    })
  })
})
export const {
  usePostSignInMutation,
  usePostRefreshTokenMutation,
  useGetAttendeeProfileQuery,
  useForgotPasswordCallMutation,
  useResetPasswordConfirmMutation,
  useChangePasswordCallMutation,
  useAccountVerifyMutation,
  useAccountConfirmMutation
} = authApi
