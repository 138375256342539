import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

// src
import './HomePage.scss'
import { useGetBannerEventsQuery } from '../../redux/api/homePageApi/homePageApi'
// import { EventIdContext } from "../ContextAPI/EventId";
// import AboutUs from './AboutUs/AboutUs'
import Banner from './Banner/Banner'
// import RecentEvents from './RecentEvents/RecentEvents'
// import UpcomingEvents from './UpcomingEvents/UpcomingEvents'

const HomePage = () => {
  // api
  const { data, isLoading, isFetching } = useGetBannerEventsQuery()

  const bannerEvent = data ?? {}
  return (
    <>
      {isLoading && isFetching && <CircularProgress className='circularProgress'/>}
      {!isLoading && !isFetching && bannerEvent && Object.keys(bannerEvent)?.length > 0 && (
        <>
          {/* <p>{eventId2}</p> */}
          <Banner bannerEvent={bannerEvent} />
          {/* <AboutUs /> */}
          {/* <UpcomingEvents /> */}
          {/* <RecentEvents /> */}
        </>
      )}
    </>
  )
}

export default HomePage
