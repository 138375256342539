import axios from 'axios'

export { default } from './InboxPage'

const API_TOKEN = process.env.REACT_APP_SENDBIRD_API_TOKEN
const APPLICATION_ID = process.env.REACT_APP_SENDBIRD_APP_ID

export const getReaction = async (channel_type, channel_url, message_id) => {
  const { data } = await axios.get(`https://api-${APPLICATION_ID}.sendbird.com/v3/emoji_categories`, {
    headers: {
      'Api-Token': API_TOKEN
    }
  })
  return data
}
