import React from 'react'

const Folder = () => {
  return (
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.3346 13.8332V20.4998C29.3346 27.1665 26.668 29.8332 20.0013 29.8332H12.0013C5.33464 29.8332 2.66797 27.1665 2.66797 20.4998V12.4998C2.66797 5.83317 5.33464 3.1665 12.0013 3.1665H18.668M29.3346 13.8332L24.0013 13.8332C20.0013 13.8332 18.668 12.4998 18.668 8.49984V3.1665M29.3346 13.8332L18.668 3.1665" stroke="#282828" strokeOpacity="0.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M9.33203 17.8335H17.332" stroke="#282828" strokeOpacity="0.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M9.33203 23.1665H14.6654" stroke="#282828" strokeOpacity="0.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}

export default Folder
