import { FormattedMessage } from 'react-intl'

export const getMonth = (date) => {
  const day = new Date(date).getMonth()
  switch (day) {
    case 0:
      return 'Jan'
    case 1:
      return 'Feb'
    case 2:
      return 'Mar'
    case 3:
      return 'Apr'
    case 4:
      return 'May'
    case 5:
      return 'June'
    case 6:
      return 'July'
    case 7:
      return 'Aug'
    case 8:
      return 'Sep'
    case 9:
      return 'Oct'
    case 10:
      return 'Nov'
    case 11:
      return 'Dec'
    default:
      break
  }
}

export const To12Hours = (time) => {
  const d = time.split(':')[0]
  const mins = time.split(':')[1]
  const extra = d % 12
  const hour = extra === 0 ? 12 : extra
  const am = d >= 12 ? 'PM' : 'AM'
  const final = hour + ':' + mins + ' ' + am
  return final
}

export const getWeekLong = (day) => {
  switch (day) {
    case 'Mon':
      <FormattedMessage id="mon" defaultMessage="Monday"/>
      return 'Mon'
    case 'Tue':
      <FormattedMessage id="tue" defaultMessage="Tuesday"/>
      return 'Tue'
    case 'Wed':
      <FormattedMessage id="wed" defaultMessage="Wednesday"/>
      return 'Wed'
    case 'Thu':
      <FormattedMessage id="thu" defaultMessage="Thursday"/>
      return 'Thu'
    case 'Fri':
      <FormattedMessage id="fri" defaultMessage="Friday"/>
      return 'Fri'
    case 'Sat':
      <FormattedMessage id="sat" defaultMessage="Saturday"/>
      return 'Sat'
    case 'Sun':
      <FormattedMessage id="sun" defaultMessage="Sunday"/>
      return 'Sun'
    default:
      break
  }
}
