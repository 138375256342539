import React from 'react'
import colors from '../../Colors.scss'
import { DayTranslate } from '../../helper/TranslateHelper/DataComponents'

export const handleFilterDay = async (day_id) => {
  const selected = document.getElementsByClassName('agenda-date')
  const circle = document.getElementsByClassName('circle')
  const arrCircle = Array.from(selected)
  arrCircle.filter((day, index) => {
    if (selected[index]?.attributes.dayId.nodeValue === day_id.toString()) {
      circle[index].style.backgroundColor = colors.color_blue
      circle[index].style.color = colors.color_white
    } else {
      circle[index].style.color = colors.color_black
      circle[index].style.backgroundColor = 'transparent'
    }
  })
  return day_id
}

export const isToDayCircle = async (eventsDay) => {
  const circle = document.getElementsByClassName('circle')
  const day = await eventsDay?.filter((day, index) => {
    const dataToday = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate().toString()
    const month = new Date().getMonth() < 10 ? '0' + new Date().getMonth() : new Date().getMonth().toString()
    const year = new Date().getFullYear()

    if (day.date.split('-')[2].includes(dataToday) && day.date.split('-')[1].includes((+month + 1).toString()) && day.date.split('-')[0].includes(year.toString())) {
      if (circle[0]) {
        circle[index].style.backgroundColor = colors.color_blue
        circle[index].style.color = colors.color_white
      }
      return day.id
    } else {
      return false
    }
  })
  if (day?.length) {
    return day[0]?.id
  }
}
export const isToDay = async (eventsDay) => {
  const day = await eventsDay?.filter((day, index) => {
    const dataToday = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate().toString()
    const month = new Date().getMonth() < 10 ? '0' + new Date().getMonth() : new Date().getMonth().toString()
    const year = new Date().getFullYear()
    if (day.date.split('-')[2].includes(dataToday) && day.date.split('-')[1].includes((+month + 1).toString()) && day.date.split('-')[0].includes(year.toString())) {
      return day.id
    } else {
      return false
    }
  })
  if (day?.length) {
    return day[0]?.id
  }
}

const Days = ({ setSelectedDate, eventsDay, setLoader }) => {
  return (
            <div className="event-agenda-calendar">
                {
                    eventsDay?.length > 1 && eventsDay.map((day) => {
                      return (
                            <div className="agenda-calendar-inside" key={day.id}>
                                <p className="agenda-day">

                                    {<DayTranslate day={new Date(day.date).toDateString().split(' ')[0]} short={true}/>}
                                </p>
                                <div className='circle'>
                                    <p
                                        /* eslint-disable-next-line react/no-unknown-property */
                                        dayid={day.id}
                                        className="agenda-date"
                                        onClick={() => {
                                          handleFilterDay(day.id).then((value) => {
                                            setSelectedDate(value)
                                            setLoader(true)
                                          })
                                        }}
                                    >
                                        {day.date.split('-')[2]}
                                    </p>
                                </div>
                            </div>
                      )
                    })}
            </div>
  )
}

export default Days
