import React from 'react'
import { FormattedMessage } from 'react-intl'
import ReactPlayer from 'react-player'
import parse from 'html-react-parser'
import { useSelector } from 'react-redux'

// src
import './PostersDetails.scss'
import { useGetPosterDetailQuery } from '../../../redux/api/posterApi/posterApi'
import { DescriptionVideo } from './DescriptionVideo'
import Attachments from '../../../components/Attachments/Attachmets'
import AsqBtnComponent from '../../../components/Buttons/AsqBtn/AsqBtn'

const PostersDetails = () => {
  // url
  const isPosterDetail = window.location.href.includes('posters') && window.location.href.includes('posters')
  const posterId = window.location.href.split('/').slice(-3)[0].toString()

  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)

  // api
  const { data: posterDetail } = useGetPosterDetailQuery({ eventId, posterId })

  if (isPosterDetail && window.screen.width < 1024) {
    return <DescriptionVideo posterDetail={posterDetail}/>
  } else {
    return (
        <div className="poster-description">
            {
                !!posterDetail &&
                <div
                    className="poster-detail-container"
                >
                    <div className="video-with-title-container">

                        <div className="poster-desc-header">
                            {!!posterDetail?.title && <h3>{posterDetail.title}</h3>}
                            {!!posterDetail?.institution &&
                                <h3 className="poster-clinic">{posterDetail.institution}</h3>}
                            {!!posterDetail?.authors && <p>
                                <strong>Authors:</strong> <span>{posterDetail.authors}</span>
                            </p>}
                        </div>
                        <div className="video-desc-container">
                            <div className="hls-player-poster ">
                                {posterDetail?.video && posterDetail?.video.embed_code
                                  ? <>
                                        {parse(
                                            `<iframe height="100%" width="100%" className="responsive-iframe" src=${posterDetail?.video.embed_code}  allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true"></iframe>`
                                        )}
                                    </>
                                  : <ReactPlayer
                                        url={posterDetail?.video?.hls_url}
                                        controls={true}
                                        width="100%"
                                        height="50%"
                                    />}
                                <div className="qsk-container">
                                    <p><span className="span-poster-desc"><strong><FormattedMessage
                                        defaultMessage="Post a question to the author "
                                        id="post-question-text"/></strong></span>
                                        <br/>
                                        <br/>
                                        <FormattedMessage
                                            id="question-text"
                                            defaultMessage="Do you want to ask an author a question? Fell free to pose your question"/>
                                    </p>
                                  <div className='qsk-container__btn'>
                                    <AsqBtnComponent id={posterDetail?.id} referrer='poster' question_type='question_poster'/>
                                  </div>
                                </div>

                            </div>
                            <div className="video-desc-with-download">

                                <div className="video-desc">
                                    {posterDetail?.body && posterDetail?.body.map((desc, index) => {
                                      return (
                                                  <div key={index}>{parse(desc?.value)}</div>
                                      )
                                    })}
                                </div>
                              <Attachments event={posterDetail}/>
                            </div>
                        </div>
                    </div>

                </div>
            }

        </div>
    )
  }
}

export default PostersDetails
