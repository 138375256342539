import React from 'react'

const NoVideo = () => {
  return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
        >
            <g data-name="Layer 57">
                <path
                    d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z"/>
                <path
                    d="M16 24a2 2 0 1 1 2-2A2 2 0 0 1 16 24zm0-2zM16 18a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v9A1 1 0 0 1 16 18z"/>
            </g>
        </svg>
  )
}

export default NoVideo
