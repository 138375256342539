import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addToken, signOut } from '../slices/authSlice/authSlice'
import axios from 'axios'
// import setAuthToken from '../../auth/SetAuthToken'

const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URI = process.env.REACT_APP_BASE_URI

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL + BASE_URI}/`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().authReducer.token
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  try {
    let result = await baseQuery(args, api, extraOptions)
    if (result?.error && result?.error?.status === 401) {
      const refreshToken = api.getState().authReducer.refreshToken
      const userId = api.getState().authReducer.userId

      await axios.post(`${BASE_URL + BASE_URI}/auth/token/refresh/`, {
        refresh: refreshToken
      }).then(async (response) => {
        api.dispatch(addToken(response.data.access))
        result = await baseQuery(args, api, extraOptions)
      })
        .catch(() => {
          if (window.location.pathname !== '/sign-in' && userId) {
            api.dispatch(signOut())
          }
        }
        )
    }
    return result
  } catch (e) {
    console.log(e)
    throw (e?.message)
  }
}
export const defaultApi = createApi({
  reducerPath: 'defaultApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({})
})
