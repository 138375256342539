import React from 'react'

const Edit = ({ width, height }) => {
  return (
        <svg width={width} height={height} viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2969 2.28125H8.51562C4.0625 2.28125 2.28125 4.0625 2.28125 8.51562V13.8594C2.28125 18.3125 4.0625 20.0938 8.51562 20.0938H13.8594C18.3125 20.0938 20.0938 18.3125 20.0938 13.8594V12.0781" stroke="#F8F8F8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <g filter="url(#filter0_d_1678_12067)">
                <path d="M14.785 3.18932L7.76687 10.2074C7.49969 10.4746 7.2325 11.0001 7.17906 11.3831L6.79609 14.0638C6.65359 15.0346 7.33937 15.7115 8.31015 15.5779L10.9909 15.1949C11.365 15.1415 11.8905 14.8743 12.1666 14.6071L19.1847 7.58901C20.3959 6.37776 20.9659 4.97057 19.1847 3.18932C17.4034 1.40807 15.9962 1.97807 14.785 3.18932Z" stroke="#F8F8F8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" shapeRendering="crispEdges"/>
            </g>
            <path d="M13.7793 4.1958C14.376 6.32439 16.0415 7.98986 18.179 8.59549" stroke="#F8F8F8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
                <filter id="filter0_d_1678_12067" x="2.02734" y="1.30713" width="23.0391" height="23.0376" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1678_12067"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1678_12067" result="shape"/>
                </filter>
            </defs>
        </svg>

  )
}

export default Edit
