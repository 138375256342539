import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

// src
import './Chats.scss'
import noAvatar from '../../../images/noAvatar.png'
import { userIsOnline } from '../SendBird/action'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import Avatar from '../../../components/Avatart/Avatar'

export default function Chats ({ user, sendBird, userMsg, connecting }) {
  // hooks
  const navigate = useNavigate()
  const location = useLocation()
  // env
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const BASE_URI = process.env.REACT_APP_BASE_URI

  // url
  const navigateToChat = location.pathname.slice(0, location.pathname.length - 6)

  // reducer
  const token = useSelector(state => state.authReducer.token)

  // state
  const [isOnline, setIsOnline] = useState([])
  const [senderId, setSenderId] = useState([])

  useEffect(() => {
    userMsg?.map((userChat) => {
      const member = userChat.members.filter((member) => {
        return member?.userId !== user?.sendbird_user_id
      })
      if (!senderId.includes(member[0]?.userId)) setSenderId(prevArray => [...prevArray, member[0]?.userId])
    })
  }, [userMsg])

  useEffect(() => {
    const ids = senderId.filter(value => value !== undefined)

    if (!ids.includes(undefined)) {
      userIsOnline(sendBird, ids).then(value => {
        setIsOnline(value)
      })
    }
  }, [senderId])
  return (

        <div className='private-chat-main'>
          {
            !connecting ? <CircularProgress className="circularProgress"/>
              : <>
            {userMsg && userMsg?.map((userChat) => {
              let imageUrl = ''
              const member = userChat.members.filter((member) => {
                if (member.userId !== user?.sendbird_user_id && member?.plainProfileUrl.includes('http')) {
                  imageUrl = `${BASE_URL + BASE_URI}/user/${member.metaData.django_id}/avatar/?auth_token=${token}`
                } else {
                  imageUrl = noAvatar
                }
                return member.userId !== user?.sendbird_user_id
              })

              return (
                    <div
                        className='private-chat-card'
                        onClick={() => {
                          if (member[0]?.metaData?.django_id) {
                            navigate(`${navigateToChat}participants/chat/${member[0]?.metaData?.django_id}`)
                          } else {
                            navigate(`${navigateToChat}participants/chat/${user?.id}`)
                          }
                        }}
                        key={userChat?.name}>
                        {userChat?.memberCount > 1
                          ? userChat?.members.map((chatUser) => {
                            if (chatUser?.nickname !== user?.full_name && chatUser?.plainProfileUrl.includes('http')) {
                              imageUrl = `${BASE_URL + BASE_URI}/user/${chatUser?.metaData?.django_id}/avatar/?auth_token=${token}`
                              if (!imageUrl) {
                                imageUrl = noAvatar
                              }
                            }
                          })
                          : null
                        }

                        <Avatar name={userChat?.members[1].nickname}
                                url={imageUrl}
                                status_online={isOnline.find((value) => value?.userId === member[0]?.userId)} />

                        <div className='private-chat-detail'>
                            <div className='private-chat-name'>
                                {userChat?.memberCount > 1

                                  ? userChat.members.map((userName, index) => {
                                    if (userName?.nickname !== user?.full_name) {
                                      return (
                                                <p key={index} className='name'>{
                                                    userName?.nickname
                                                }</p>
                                      )
                                    }
                                  })
                                  : userChat?.members[0].nickname
                                }

                                <p className='time'>{userChat?.lastMessage?.createdAt && new Date(userChat?.lastMessage?.createdAt).getHours()}:{userChat?.lastMessage?.createdAt && new Date(userChat?.lastMessage?.createdAt).getMinutes() < 10
                                  ? '0' + userChat?.lastMessage?.createdAt && new Date(userChat?.lastMessage?.createdAt).getMinutes()
                                  : userChat?.lastMessage?.createdAt && new Date(userChat?.lastMessage?.createdAt).getMinutes()}</p>
                            </div>
                            <div className='private-chat-msg'>
                                <p className='msg' style={{ lineBreak: 'anywhere' }}>{

                                    userChat?.lastMessage?.type
                                      ? userChat?.lastMessage?.type === 'image/jpeg' || userChat?.lastMessage?.type === 'image/png' ? 'Photo' : 'File'
                                      : userChat?.lastMessage?.message.length > 50 ? userChat?.lastMessage?.message.slice(0, 50) + '...' : userChat?.lastMessage?.message
                                }</p>
                                {userChat.unreadMessageCount > 0 &&
                                    <p className='msg-numbers'>{userChat?.unreadMessageCount}</p>}
                            </div>
                        </div>
                    </div>
              )
            })}
                </>
          }
        </div>
  )
}
