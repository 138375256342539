import { defaultApi } from '../splitApi'

export const posterApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getPosters: build.query({
      async queryFn ({ eventId, searchValue = '', orderBy = '', order = '', limit = '', offset = '' },
        { signal, dispatch, getState },
        extraOptions, fetchWithBQ) {
        if (!searchValue?.length > 0) {
          const result = await fetchWithBQ(`base/events/${eventId}/posters/${orderBy && `?order=${order}`}${orderBy}${+limit !== 0 ? `&limit=${limit}` : ''}${offset && `&offset=${offset}`}`)
          return result
        } else {
          const result = await fetchWithBQ(`/base/events/${eventId}/posters/?search=${searchValue}`)
          return result
        }
      }
    }),
    getPosterDetail: build.query({
      query: ({ eventId, posterId }) => `/base/events/${eventId}/posters/${posterId}/`
    })
  })
})

export const { useGetPostersQuery, useGetPosterDetailQuery } = posterApi
