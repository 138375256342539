import { defineMessages } from 'react-intl'

export default defineMessages({
  public_chat: {
    id: 'public-chat',
    defaultMessage: 'Public Chat'
  },
  private_chat: {
    id: 'private-chat',
    defineMessages: 'Private Chat'
  }
})
