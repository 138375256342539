import React from 'react'
import colors from '../../../../Colors.scss'

const InstitutionSVG = ({ width = '22', height = '24', color = colors.color_blue }) => {
  return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.63478 20.7733V17.7156C9.63478 16.9351 10.2722 16.3023 11.0584 16.3023H13.9326C14.3102 16.3023 14.6723 16.4512 14.9393 16.7163C15.2063 16.9813 15.3563 17.3408 15.3563 17.7156V20.7733C15.3539 21.0978 15.4821 21.4099 15.7124 21.6402C15.9427 21.8705 16.2561 22 16.5829 22H18.5438C19.4596 22.0023 20.3388 21.6428 20.9872 21.0008C21.6356 20.3588 22 19.487 22 18.5778V9.86686C22 9.13246 21.6721 8.43584 21.1046 7.96467L14.434 2.67587C13.2737 1.74856 11.6111 1.7785 10.4854 2.74698L3.96701 7.96467C3.37274 8.42195 3.01755 9.12064 3 9.86686V18.5689C3 20.4639 4.54738 22 6.45617 22H8.37229C9.05123 22 9.603 21.4562 9.60792 20.7822L9.63478 20.7733Z"
                                                fill={color}
            />
        </svg>
  )
}

export default InstitutionSVG
