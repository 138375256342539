import { defaultApi } from '../splitApi'
import { addTimeReq } from '../../slices/participantSlice/participantSlice'

export const participantApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getCountries: build.query({
      query: () => '/base/choices/countries/',
      transformResponse: (response) => {
        return response.items
      }
    }),
    getEventAttendees: build.query({

      async queryFn ({ eventId, orderBy, order, searchValue, limit, offset }, _queryApi, _extraOptions, fetchWithBQ) {
        const participant = await fetchWithBQ(`base/events/${eventId}/people/?${orderBy ? `order_by=${order}people__${orderBy}` : ''}${searchValue ? `&search=${searchValue}` : ''}${limit ? `&limit=${limit}` : ''}${offset ? `&offset=${offset}` : ''}`)
        _queryApi.dispatch(addTimeReq(new Date().getTime()))
        return { data: participant?.data }
      }

    }),
    getSortedAttendees: build.query({
      async queryFn ({ eventId, orderByTable, orderTable, searchValueTable, limit, offsetTable }, _queryApi, _extraOptions, fetchWithBQ) {
        const participant = await fetchWithBQ(`base/events/${eventId}/people/?${orderByTable ? `order_by=${orderTable}people__${orderByTable}` : ''}${searchValueTable ? `&search=${searchValueTable}` : ''}${limit ? `&limit=${limit}` : ''}${offsetTable ? `&offset=${offsetTable}` : ''}`)
        _queryApi.dispatch(addTimeReq(new Date().getTime()))
        return { data: participant?.data }
      }
    }),
    getAttendeeProfileEvent: build.query({
      query: ({ eventId, profileId }) => `base/events/${eventId}/people/${profileId}`
    })
  })

})
export const {
  useGetCountriesQuery, useGetEventAttendeesQuery, useGetSortedAttendeesQuery, useGetAttendeeProfileEventQuery
} = participantApi
