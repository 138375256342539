import { createTheme } from '@mui/material'
import colors from './Colors.scss'

export const appTheme = createTheme({
  palette: {
    primary: {
      light: colors.primary_light,
      main: colors.primary_main,
      dark: colors.primary_dark,
      contrastText: '#fff'
    },
    secondary: {
      light: colors.secondary_light,
      main: colors.secondary_main,
      dark: colors.secondary_dark,
      contrastText: '#000'
    },
    third: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000'
    }
  }
})
